import React, {Component} from "react";
import Slick from 'react-slick';

import "../assets/css/slick.css";
import "../assets/css/slick-theme.css";

class MiniSlider extends Component {


    render() {

        var slider_settings = {
            dots: false,
            infinite: true,
            speed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            //fade: true,
            autoplay: true,
            autoplaySpeed: 6000,
            lazyLoad: true
        };

        return <div className="col mini-slider-container">
            <Slick {...slider_settings}>
                {this.getImages(this.props.images)}
            </Slick>
        </div>


    }

    getImages(images) {
        let images_html = [];
        images.forEach(function (image, index) {
            images_html.push(<img key={index} src={image} alt=""/>);
        });
        return images_html;
    }
}

export default MiniSlider;
