import React, {Component} from "react";

class Footer extends Component {
    render() {
        return <footer className="container-fluid">
            <div className="row">
                <div className="col">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-5 footer-left">
                                <img src="assets/images/logo-new-footer.png" alt=""/>
                                <div className="col">
                                    If anything truly defines Ella Flower Garden Resorts, it is the value we attach to
                                    lasting memories.We offer experiences that are authentically local, in hotels and
                                    resorts of
                                    unrivaled presence. And genuine hospitality is achieved when
                                    engaging service and attention to detail elevate each stay into
                                    cherished memory.
                                </div>
                            </div>
                            <div className="col-sm-2 footer-middle">
                                <div className="col heading">CONTACT</div>
                                <div className="col">
                                    3rd Mile Post, Passara Rd,
                                    Ella, Sri Lanka
                                    <br/>
                                    <br/>
                                    +94 57 2050 480
                                    <br/>
                                    +94 57 4933 163
                                    <br/>
                                    <br/>
                                    ellaflowergarden@gmail.com
                                    <br/>
                                    info@ella-resort.com

                                </div>
                            </div>
                            <div className="col-sm-5 footer-rights">
                                <div className="col heading newsletter">
                                    Subscribe to our newsletter
                                </div>
                                <div className="col newsletter">
                                    <form className="form-inline col">
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="email"
                                                   placeholder="YOUR EMAIL ADDRESS"/>
                                        </div>
                                        <button type="submit" className="btn btn-primary">SUBSCRIBE</button>
                                    </form>
                                </div>
                                <div className="col heading">FOLLOW US</div>
                                <div className="col">
                                    <a href="https://www.facebook.com"><i className="fab fa-facebook-f"></i></a>
                                    <a href="https://www.twitter.com"><i className="fab fa-twitter"></i></a>
                                    <a href="https://www.instagram.com"><i className="fab fa-instagram"></i></a>
                                    <a href="https://www.pinterest.com"><i className="fab fa-pinterest-p"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center credit">
                                Website designed and developed by <a href="http://www.shawebdesign.com">ShaWeb
                                Design</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    }
}

export default Footer;
