import React, {Component} from "react";

class DateInput extends Component {

    render() {
        return <input type="date" placeholder={this.props.placeholder} min={this.props.min}
                      className={'form-control ' + this.props.className}
                      onChange={this.changeDate.bind(this)}/>
    }

    changeDate(event) {
        this.props.setDate(event.target.value);
    }
}

export default DateInput;
