import React, {Component} from "react";

import {CSSTransition} from 'react-transition-group'; // ES6

class Errors extends Component {


    render() {
        // if (this.props.errors.length) {
        return <CSSTransition
            in={this.getErrorStatus(this.props.errors.length)}
            timeout={200}>
            <div className="alert alert-danger">
                <ul>{this.getErrors(this.props.errors)}</ul>
            </div>
        </CSSTransition>
        // } else {
        //     return '';
        // }
    }

    getErrors(errors) {
        let errors_html = [];
        errors.forEach(function (error, index) {
            errors_html.push(<li key={index}>{error}</li>);
        });
        return errors_html;
    }

    getErrorStatus(length) {
        if (length <= 0) {
            return false;
        } else {
            return true;
        }
    }
}

export default Errors;
