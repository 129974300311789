import React, {Component} from "react";
import {Link} from "@reach/router";
import NavLink from "./NavLink";
import {CSSTransition} from "react-transition-group";


class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navStatus: false
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', function (event) {
            if (document.querySelector('header .navbar')) {
                if (document.scrollingElement.scrollTop > 100) {
                    document.querySelector('header .navbar').classList.add('no-background');
                } else {
                    document.querySelector('header .navbar').classList.remove('no-background');
                }
            }
        });
    }

    render() {
        return <header className="container-fluid">
            <div className="row">
                <div className="col">
                    <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                        <Link to="/" className="navbar-brand"><img src="assets/images/logo-new.png" alt=""/></Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation"
                                onClick={this.toggleNavBar.bind(this)}>
                            <i className="fas fa-bars"></i>
                        </button>
                        <CSSTransition in={this.state.navStatus} timeout={200} className="header-nav">
                            <div
                                className="collapse navbar-collapse"
                                id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <NavLink to="/" className="nav-link">Home</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/accommodation" className="nav-link">Accommodation</NavLink>
                                    </li>
                                    <li className="nav-item active">
                                        <NavLink to="/restaurant" className="nav-link">Restaurant</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/location" className="nav-link">Location</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/things-to-do" className="nav-link">Things To Do</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/special-offers" className="nav-link special">Special
                                            Offers</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/gallery" className="nav-link">Gallery</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/contact-us" className="nav-link">Contact Us</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </CSSTransition>
                    </nav>
                </div>
            </div>
        </header>
    }

    toggleNavBar = () => {
        let navStatus = !this.state.navStatus;
        this.setState({navStatus: navStatus});
    }
}

export default Header;
