import React, {Component} from "react";

class TextInput extends Component {

    render() {
        return <input className="form-control" type="text" placeholder={this.props.placeholder}
                      onChange={this.setText.bind(this)} defaultValue={this.props.defaultValue}/>
    }

    setText(event) {
        this.props.setText(event.target.value);
    }
}

export default TextInput;
