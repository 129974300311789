import React, {Component} from "react";
import Slick from 'react-slick';

import "../assets/css/slick.css";
import "../assets/css/slick-theme.css";
import DateInput from "./DateInput";
import Errors from "./Errors";
import axios from 'axios';
import moment from "moment";
import Success from "./Success";

import Countries from "../data/Countries";
import Nationalities from "../data/Nationalities";
import {CSSTransition} from "react-transition-group";
import NumberInput from "./NumberInput";
import TextInput from "./TextInput";
import Rooms from "../data/Rooms";
import SearchResult from "./SearchResult";

import lodash from 'lodash';

class Slider extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.baseUrl = 'https://itesholdings.com/OnlineResAPI/';
    this.state = {
      errors: [],
      success: [],
      maps_height: '',
      stage: 1,
      fromDate: '',
      toDate: '',
      searchResults: [],
      roomTypes: [],
      selectedRoomTypes: [],
      guests: 0,
      adultCount: 0,
      countries: Countries.getCountries(),
      nationalities: Nationalities.getNationalities(),
      country: '',
      title: '',
      firstName: '',
      lastName: '',
      telephone: '',
      email: '',
      confirmEmail: '',
      address: '',
      postcode: '',
      nationality: '',
      city: '',
      childrenCount: 0,
      bookedBy: '',
      packages: [
        {key: 'BB', value: 'Bread and Breakfast'},
        {key: 'HB', value: 'Half Board'},
        {key: 'FB', value: 'Full Board'},
        {key: 'AI', value: 'All Inclusive'},
      ],
      currencyValue: 100,
      arrival: '',
      comments: '',
      reference: '',
      modalStatus: false,
      confirmModalStatus: false,
      rooms: Rooms.getRooms(),
      scrollTrigger: false,
      roomRates: [],
      total: 0,
      confirmEnabled: false,
      mobile: false,
      days: 0,
      specialRequests: '',
      termsModalStatus: false,
      loader: false
    };

    this.searchRef = React.createRef();
  }

  /**
   *
   */
  componentDidMount() {

    // if (sessionStorage.getItem('data')) {
    //     this.setState(JSON.parse(sessionStorage.getItem('data')));
    //     this.setPreviousState();
    // }

    var image_elements = document.querySelectorAll('.slider .slider-container img');
    if (window.innerWidth > 992) {
      document.querySelector('.slider .slider-wrapper').style.height = window.innerHeight + 'px';

      image_elements.forEach(function (image_element) {
        image_element.style.height = window.innerHeight + 'px'
      });
    } else {

      let windowHeight = window.innerHeight + 88;
      // document.querySelector('.slider .slider-wrapper').style.height = windowHeight + 'px';
      document.querySelector('.slider .slider-wrapper').style.height = 'auto';

      image_elements.forEach(function (image_element) {
        // image_element.style.height = windowHeight + 'px';
        image_element.style.height = 'auto';
      });
    }
    if (this.props.maps && this.props.maps === true) {
      this.setState({maps_height: window.innerHeight + 'px'});
    }

    if (window.innerWidth < 767) {
      this.setState({mobile: true});
    } else {
      this.setState({mobile: false});
    }

  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.scrollTrigger) {
      this.searchRef.current.scrollIntoView({behavior: "smooth"});
      this.setState({scrollTrigger: false});
    }
    // sessionStorage.setItem('data', JSON.stringify(this.state));
  }

  /**
   *
   * @returns {*}
   */
  render() {

    var slider_settings = {
      dots: false,
      infinite: true,
      speed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 6000,
      fade: true,
    };

    return <div>
      {this.state.loader ?
        <div id="loader">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div> : <span></span>
      }
      <section className="container-fluid slider">
        <div className="row">
          <div className="col slider-wrapper">

            <div className="col slider-container">
              <Slick {...slider_settings}>
                {this.getImages(this.props.images)}
              </Slick>
            </div>
            <div className="slider-contact">
              <div className="float-left left">info@ella-resort.com</div>
              <div className="float-left right">Call Us: +94 57-2050-480</div>
            </div>
            {this.state.mobile ?
              <div className="booking-widget">
                <div className="booking-widget-wrapper float-left">
                  <div className="booking-widget-box float-left">
                    <form>
                      <div className="input-wrapper form-group float-left text-right">
                        <button
                          type="button" onClick={this.setMobile.bind(this)}>
                          Book Now
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              :
              <div className="booking-widget">
                <div className="booking-widget-wrapper float-left">
                  <Errors errors={this.state.errors}/>
                  <Success success={this.state.success}/>
                  <div className="booking-widget-box float-left">
                    <form>

                      <div className="input-wrapper form-group float-left">
                        <label htmlFor="fromDate"> From:</label>
                        <DateInput setDate={this.setFromDate.bind(this)}
                                   min={moment().format('YYYY-MM-DD')}/>
                      </div>
                      <div className="input-wrapper form-group float-left">
                        <label htmlFor="toDate" className=""> To:</label>
                        <DateInput setDate={this.setToDate.bind(this)}
                                   min={moment(this.state.fromDate).add(1, 'days').format('YYYY-MM-DD')}/>
                      </div>

                      <div className="input-wrapper form-group float-left">
                        <label htmlFor="adultCount">Number of adults:</label>
                        <NumberInput placeholder="Number of adults" step="1" min="1"
                                     setNumber={this.setAdultCount.bind(this)}/>
                      </div>
                      <div className="input-wrapper form-group float-left">
                        <label htmlFor="childrenCount">Number of children:</label>
                        <NumberInput placeholder="Number of children" step="1" min="0"
                                     setNumber={this.setChildrenCount.bind(this)}/>
                      </div>

                      <div className="input-wrapper form-group float-left text-right">
                        <button
                          type="button" onClick={this.getAvailability.bind(this)}>
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </section>
      <CSSTransition
        in={this.getSuccessStatus(this.state.searchResults.length)}
        timeout={200} className="search-results container">
        <section>
          <div className="row" ref={this.searchRef}>
            <div className="col">
              <h2>Search Results</h2>
              <div className="row">
                <div className="col-12 col-sm-9">
                  <div className="row booking-results">
                    {this.getSearchResults()}
                  </div>
                </div>
                <div className="col-12 col-sm-3">
                  <div className="row booking-cart">
                    {this.state.selectedRoomTypes.length ?
                      this.getBookingCart()
                      :
                      <h2>No Room Selected. Please select a room!</h2>
                    }

                    <div className="col-12 special-requests">
                      <div className="form-group">
                        <label htmlFor="requests">Special Requests</label>
                        <textarea className="form-control"
                                  onKeyUp={this.setSpecialRequests.bind(this)}
                                  placeholder="Special Requests"></textarea>
                      </div>
                    </div>
                    <div className="col-12 total">
                      Total: {this.getTotalFormat()}
                    </div>

                    {this.state.selectedRoomTypes.length ?
                      <div className="col-12">
                        <button type="button" className="btn btn-primary"
                                onClick={this.openModal.bind(this)}>Confirm
                        </button>
                      </div> : <div></div>
                    }
                  </div>
                  <div className="row">
                    <div className="col">
                      <ul className="payment-options">
                        <li><img src="assets/images/image002.png" alt=""/></li>
                        <li><img src="assets/images/Master Secure.png" alt=""/></li>
                        <li><img src="assets/images/UnionPay Logo-01.jpg" alt=""/></li>
                        <li><img src="assets/images/vbm_blu01.png" alt=""/></li>
                        <li><img src="assets/images/vbv.png" alt=""/></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </CSSTransition>
      <CSSTransition
        in={this.state.modalStatus}
        timeout={200} className="gallery-light-box">
        <div className="gallery-light-box" key="gallery-light-box">
          <div className="gallery-light-box-close float-right">
            <button type="button" onClick={this.closeModal.bind(this)}>X</button>
          </div>
          <div className="modal-content text-left">
            <div className="row">
              <div className="col text-center">
                <h2>Booking Details</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-10 offset-sm-1">
                <Errors errors={this.state.errors}/>
                <form>
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="firstName">Title:</label>
                        <select defaultValue={this.state.title}
                                onChange={this.setTitle.bind(this)} name="title" id="title"
                                className="form-control">
                          <option value="">Select a title
                          </option>
                          <option value="Mr.">Mr.
                          </option>
                          <option value="Mrs.">Mrs.
                          </option>
                          <option value="Miss">Miss
                          </option>
                          <option value="Ven">Ven
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="firstName">First Name:</label>
                        <TextInput setText={this.setFirstName.bind(this)}
                                   placeholder="First Name"
                                   defaultValue={this.state.firstName}/>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="lastName">Last Name:</label>
                        <TextInput setText={this.setLastName.bind(this)}
                                   placeholder="Last Name" defaultValue={this.state.lastName}/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="city">City:</label>
                        <TextInput setText={this.setCity.bind(this)} placeholder="City"
                                   defaultValue={this.state.city}/>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="country">Country:</label>
                        <select className="form-control" onChange={this.setCountry.bind(this)}
                                defaultValue={this.state.country}>
                          {this.getCountries()}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="nationality">Nationality:</label>
                        <select className="form-control"
                                onChange={this.setNationality.bind(this)}
                                defaultValue={this.state.nationality}>
                          {this.getNationalities()}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <TextInput setText={this.setEmail.bind(this)} placeholder="Email"
                                   defaultValue={this.state.email}/>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="confirmEmail">Confirm Email:</label>
                        <TextInput setText={this.setConfirmEmail.bind(this)}
                                   placeholder="Confirm Email"
                                   defaultValue={this.state.confirmEmail}/>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="telephone">Telephone:</label>
                        <TextInput setText={this.setTelephone.bind(this)}
                                   placeholder="Telephone" defaultValue={this.state.telephone}/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="bookedBy">Booked By:</label>
                        <TextInput setText={this.setBookedBy.bind(this)}
                                   placeholder="Booked By" defaultValue={this.state.bookedBy}/>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="address">Address:</label>
                        <TextInput setText={this.setAddress.bind(this)}
                                   placeholder="Address" defaultValue={this.state.address}/>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="postcode">PostCode:</label>
                        <TextInput setText={this.setPostcode.bind(this)}
                                   placeholder="Postcode" defaultValue={this.state.postcode}/>
                      </div>
                    </div>
                  </div>

                  {/*<div className="row">*/}
                  {/*    <div className="col-12 col-sm-4">*/}
                  {/*        <div className="form-group">*/}
                  {/*            <label htmlFor="arrival">Arrival:</label>*/}
                  {/*            <TextInput setText={this.setArrival.bind(this)} placeholder="Arrival"/>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="comments">Comments:</label>
                        <textarea rows="2" className="form-control"
                                  defaultValue={this.state.comments}
                                  onChange={this.setComments.bind(this)}></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="comments"><input type="checkbox"
                                                         onChange={this.setConfirmEnabled.bind(this)}
                                                         checked={this.state.confirmEnabled}/> I
                          accept the <a href={'#'} onClick={this.openTermsModal.bind(this)}>terms
                            and
                            conditions</a>.</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-right">
                      <button disabled={!this.state.confirmEnabled}
                              onClick={this.confirmBookingView.bind(this)}
                              className="btn btn-primary"
                              type="button">Confirm Booking
                      </button>
                      <ul className="payment-options">
                        <li><img src="assets/images/image002.png" alt=""/></li>
                        <li><img src="assets/images/Master Secure.png" alt=""/></li>
                        <li><img src="assets/images/UnionPay Logo-01.jpg" alt=""/></li>
                        <li><img src="assets/images/vbm_blu01.png" alt=""/></li>
                        <li><img src="assets/images/vbv.png" alt=""/></li>
                      </ul>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={this.state.confirmModalStatus}
        timeout={200} className="gallery-light-box">
        <div className="gallery-light-box" key="gallery-light-box">
          <div className="gallery-light-box-close float-right">
            <button type="button" onClick={this.closeConfirmModal.bind(this)}>X</button>
          </div>
          <div className="modal-content text-left">
            <div className="row">
              <div className="col text-center">
                <h2>Booking Details</h2>
              </div>
            </div>
            <div className="row booking-confirmation">
              <div className="col-sm-10 offset-sm-1">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="row">
                      <div className="col-4 label">Arrival:</div>
                      <div className="col-8 value">
                        {this.state.fromDate}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="row">
                      <div className="col-4 label">Departure:</div>
                      <div className="col-8 value">
                        {this.state.toDate}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="row">
                      <div className="col-4 label">Count:</div>
                      <div className="col-8 value">
                        {this.getCountFormat()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="row">
                      <div className="col-4 label">Name:</div>
                      <div className="col-8 value">
                        {this.state.title} {this.state.firstName} {this.state.lastName}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="row">
                      <div className="col-4 label">Address:</div>
                      <div className="col-8 value">
                        {this.state.address}, {this.state.city}, {this.state.postcode}, {this.state.country}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="row">
                      <div className="col-4 label">Nationality:</div>
                      <div className="col-8 value">
                        {this.state.nationality}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="row">
                      <div className="col-4 label">Email:</div>
                      <div className="col-8 value">
                        {this.state.email}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="row">
                      <div className="col-4 label">Telephone:</div>
                      <div className="col-8 value">
                        {this.state.telephone}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="row">
                      <div className="col-4 label">Booked By:</div>
                      <div className="col-8 value">
                        {this.state.bookedBy}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-4 col-sm-2 label">Comments:</div>
                      <div className="col-8 col-sm-10 value">
                        {this.state.comments}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-4 col-sm-2 label">Special Requests:</div>
                      <div className="col-8 col-sm-10 value">
                        {this.state.specialRequests}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row booking-details">
                  {this.getBookingCart()}
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row total">
                      <div className="col-4 col-sm-2 label">Total:</div>
                      <div className="col-8 col-sm-10 value">
                        {this.getTotalFormat()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <button disabled={!this.state.confirmEnabled}
                            onClick={this.confirmBooking.bind(this)}
                            className="btn btn-primary"
                            type="button">Confirm Booking
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={this.state.termsModalStatus}
        timeout={200} className="gallery-light-box">
        <div className="gallery-light-box" key="gallery-light-box">
          <div className="gallery-light-box-close float-right">
            <button type="button" onClick={this.closeTermsModal.bind(this)}>X</button>
          </div>
          <div className="modal-content text-left">
            <div className="row">
              <div className="col text-center">
                <h2>Terms and Conditions</h2>
              </div>
            </div>
            <div className="row terms">
              <div className="col">
                <h2>Reservations</h2>

                All rates are quoted in UNITED STATES DOLLARS.
                <br/>
                All reservations and cancellations must be confirmed to the hotel attn. reservation team
                by email using the following contact details;
                <br/>
                E-mail: ellaflowergarden@gmail.com
                <br/>
                <br/>
                For all reservation confirmed will receive a confirmation voucher with the reference
                number from the resort. If not received please do contact the reservation department on
                0094 71 62 99 900 and get the confirmation, Verbal or Written email confirmations will
                not be considered as confirmation.
                <br/>
                <br/>
                Special request please check future reservations two month before the guest check-in
                date, and we don't confirm your booking with bellow words.
                <br/>
                (Your booking is confirmed and we'll send you the per-forma invoice ASAP..)
                <br/>
                If is confirm we will send proforma invoice.

                <h2>Resort</h2>

                The EFGR Management would like to inform guests that we would like to respect the guests
                freedom and their satisfaction, therefore we strictly prohibit guest of having late
                night parties and loud music at rooms which will disturb the others sleep.
                <br/>
                <br/>
                We EFGR have provided free WIFI in all area and we would like to request guest to use
                wisely and to conserve so everyone can enjoy the benefits.
                <br/>
                If the guests need the room
                made up please be kind enough to inform the front desk and the room will only be on
                request only.
                <br/>
                Please conserve and save water and electricity as we are responsible for
                the earth life.
                <br/>
                For the safety of the Hotel, Please note that the front gate will be
                closed @ 21.30hrs.
                <br/>
                We kindly ask guest to avoid bringing outsiders to the premises and
                if it is a must please.

                <h2>Child Policy</h2>

                Infants/Children 0-5yrs will be entitled for a free accommodation and entitled to the
                parent's meal plan.
                <br/>
                Children between 6 - 11 yrs. would be charged 50% of the applicable meal rates.

                <h3>Cancellation / Amendment and No show Policy</h3>

                Early Departures == No Refund
                <br/>
                No Show == No Refund
                <br/>
                Cancellation within 14 days to arrival == No Refund
                <br/>
                Cancellation before 15 days to arrival == 100% of the bill value

                <h2>Check -in / Check out</h2>

                <div className={'check-in'}>
                  Check - in 14:00hrs
                  <br/>
                  <br/>
                  Check - out 11:00hrs
                </div>

                <br/>
                <br/>
                Late check - Outs will be a subject to an extra charge of 50% of the accommodation value
                of a day. (this will be applied for guest who are residing till 18:00hrs)
                <br/>
                Late check - Outs beyond 18:00hrs will be entitled to a 150% accommodation charge of a
                day.
                <br/>
                Early check - In's between 00:00 - 10:00hrs will be entitled for a 100% accommodation
                pay as per the cost booking for a day.

                <h2>Taxation</h2>

                <span className={'highlight'}>All rates are Without of taxes.</span>
                <br/>
                <br/>
                Further on, any other tax or levy imposed by the government of Sri Lanka, is applicable
                at he time of invoicing will also be added to the above rates. We will also be entitled
                to deduct any withholding taxes, if any will be in effect as to the regulations
                introduced by the government of Sri Lanka.

                <br/>
                <br/>
                <button type={'button'} onClick={this.closeTermsModal.bind(this)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  }

  //Getters
  /**
   *
   * @param images
   * @returns {[]}
   */
  getImages = (images) => {
    let images_html = [];
    images.forEach(function (image, index) {
      images_html.push(<img key={index} src={image} alt=""/>);
    });
    return images_html;
  };

  /**
   *
   * @returns {[]}
   */
  getSearchResults = () => {
    let searchResults = [];

    let searchResultsClone = [];

    let searchResultsSorted = lodash.orderBy(this.state.searchResults, ['avalRoomtype'], ['asd']);

    searchResultsSorted.forEach((result, index) => {

      let room = this.state.rooms.find(room => {
        return result.avalRoomtype.trim().toLowerCase() === room.mapper.trim().toLowerCase()
      });

      let ratesOfRoom = this.state.roomRates.filter(rateOfRoom => {
        return result.avalRoomtype.trim().toLowerCase() === rateOfRoom.roomType.trim().toLowerCase();
      });

      let existing = searchResultsClone.filter(searchResultClone => {
        return result.avalRoomtype === searchResultClone.avalRoomtype;
      });

      let rateFlag = false;

      ratesOfRoom.forEach(rateOfRoom => {
        if (rateOfRoom.roomRate > 0) {
          rateFlag = true;
        }
      });

      if (this.getAvailabilityCheck(result.avalability, result.maxNoOfGuest) && !existing.length && rateFlag) {
        searchResults.push(<SearchResult result={result} room={room} setRoomType={this.setRoomType.bind(this)}
                                         key={index} packages={this.state.packages}
                                         ratesOfRoom={ratesOfRoom}/>);

        searchResultsClone.push(result);
      }
    });
    return searchResults;
  };

  /**
   *
   * @returns {[]}
   */
  getBookingCart = () => {
    let bookingCart = [];

    this.state.selectedRoomTypes.forEach((selectedRoomType, index) => {

      let selectedPackage = this.state.packages.find(packageItem => {
        return packageItem.key === selectedRoomType.package;
      });
      if (selectedRoomType.quantity > 0) {
        bookingCart.push(<div className="col-12" key={index}>
          <div className="col-12 booking-wrapper">
            <h2>{selectedRoomType.name}</h2>
            <div className="col-12 details table-responsive">
              <table className="table table-borderless">
                <tbody>
                <tr>
                  <td>
                    Selected Package:
                  </td>
                  <td>
                    {selectedPackage.value}
                  </td>
                </tr>
                <tr>
                  <td>Rooms:</td>
                  <td>{selectedRoomType.quantity}</td>
                </tr>


                <tr>
                  <td>Number of days:</td>
                  <td>{this.state.days}</td>
                </tr>
                <tr>
                  <td>Price:</td>
                  <td>{selectedRoomType.price}</td>
                </tr>
                <tr>
                  <td>Sub Total:</td>
                  <td>{this.getFormattedPrice(this.state.days * selectedRoomType.priceValue * selectedRoomType.quantity)}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>);
      }
    });

    return bookingCart;
  };

  /**
   *
   * @returns {boolean}
   */
  getAvailability = () => {
    if (!this.state.fromDate) {
      this.setState({fromDate: '', errors: ['Please select a valid future from date!']});
      return false;
    }

    if (!this.state.toDate) {
      this.setState({toDate: '', errors: ['Please select a valid future to date!']});
      return false;
    }
    if (!this.state.adultCount) {
      this.setState({adultCount: '', errors: ['Please enter valid adult count!']});
      return false;
    }
    if (this.state.childrenCount < 0 || this.state.childrenCount === '') {
      this.setState({childrenCount: '', errors: ['Please enter valid children count!']});
      return false;
    }


    this.sendDates();
  };

  /**
   *
   * @returns {*[]}
   */
  getCountries = () => {
    let countries = [<option key={0} value="">Select a country</option>];

    this.state.countries.forEach((country, index) => {
      countries.push(<option key={index + 1} value={country}
      >{country}</option>)
    });


    return countries;
  };

  /**
   *
   * @returns {*[]}
   */
  getNationalities = () => {
    let nationalities = [<option key={0} value="">Select a nationality</option>];

    this.state.nationalities.forEach((nationality, index) => {
      nationalities.push(<option key={index + 1} value={nationality}
      >{nationality}</option>)
    });


    return nationalities;
  };

  /**
   *
   * @param length
   * @returns {boolean}
   */
  getSuccessStatus(length) {
    if (length <= 0) {
      return false;
    } else {
      return true;
    }
  }

  /**
   *
   * @param availability
   * @param maxNoOfGuests
   * @returns {boolean | boolean}
   */
  getAvailabilityCheck = (availability, maxNoOfGuests) => {
    let availabilityStatus = availability > 0 && maxNoOfGuests >= this.state.adultCount;
    if (this.state.childrenCount > 2) {
      availabilityStatus = availabilityStatus && (maxNoOfGuests >= this.state.adultCount + this.state.childrenCount);
    }
    return availabilityStatus;
  };

  /**
   *
   */
  getRoomRates = () => {
    this.setState({loader: true});
    axios.get(this.baseUrl + 'getRoomRates/?frmdate=' + this.state.fromDate + '&todate=' + this.state.toDate)
      .then(res => {
        this.setState({roomRates: res.data});

        let packages = [];

        res.data.forEach(rate => {
          let foodPackage = rate.foodPackage;

          let ratePrice = rate.roomRate;

          let packageCheck = packages.find(singlePackage => {
            return singlePackage.key === foodPackage;
          });

          if (!packageCheck) {
            let packageName = '';

            switch (foodPackage) {
              case 'BB':
                packageName = 'Bed and Breakfast';
                break;
              case 'HB':
                packageName = 'Half Board';
                break;
              case 'FB':
                packageName = 'Full Board';
                break;
              case 'AI':
                packageName = 'All Inclusive';
                break;
              default:
                packageName = foodPackage;
                break;
            }
            if (ratePrice > 0) {
              packages.push({key: foodPackage, value: packageName});
            }
          }
        });

        this.setState({packages: packages});
        this.setState({loader: false});


      })
      .catch(error => {
        console.error(error);
      });
  };
  /**
   *
   */
  getRoomDetails = () => {
    this.setState({loader: true});

    axios.get(this.baseUrl + 'getroomTypes')
      .then(res => {

        let roomDetails = res.data;

        this.state.searchResults.forEach(searchResult => {
          let roomType = roomDetails.find(roomDetail => {
            return roomDetail.showInAPI === searchResult.avalRoomtype;
          });

          searchResult.id = roomType.rtypeNo;
        });
        this.setState({loader: false});

      })
      .catch(error => {
        console.error(error);
      });
  };

  /**
   *
   * @returns {number}
   */
  getTotal = () => {
    let total = 0;

    this.state.selectedRoomTypes.forEach(selectedRoomType => {
      total += selectedRoomType.quantity * parseFloat(selectedRoomType.priceValue) * this.state.days;
    });
    return total;
  };

  /**
   *
   * @returns {string}
   */
  getTotalFormat = () => {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(this.state.total);
  };

  /**
   *
   * @returns {string}
   */
  getCountFormat = () => {
    let format = '';
    if (this.state.adultCount === 0) {
      format += ' No adults ';
    } else {

      if (this.state.adultCount > 1) {
        format += ' ' + this.state.adultCount + ' adults ';
      } else {
        format += ' One adult '
      }
    }

    if (this.state.childrenCount === 0) {
      format += ' No children ';
    } else {

      if (this.state.childrenCount > 1) {
        format += ' ' + this.state.childrenCount + ' children ';
      } else {
        format += ' One child '
      }
    }

    return format;
  };

  /**
   *
   * @param price
   * @returns {string}
   */
  getFormattedPrice = (price) => {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(price);
  };

  //Setters
  /**
   *
   * @param date
   */

  setInitialState = () => {
    this.setState({
      errors: [],
      success: [],
      stage: 1,
      fromDate: '',
      toDate: '',
      searchResults: [],
      roomTypes: [],
      selectedRoomTypes: [],
      guests: 0,
      adultCount: 0,
      countries: Countries.getCountries(),
      nationalities: Nationalities.getNationalities(),
      country: '',
      title: '',
      firstName: '',
      lastName: '',
      telephone: '',
      email: '',
      confirmEmail: '',
      address: '',
      postcode: '',
      nationality: '',
      city: '',
      childrenCount: 0,
      bookedBy: '',
      packages: [
        {key: 'BB', value: 'Bread and Breakfast'},
        {key: 'HB', value: 'Half Board'},
        {key: 'FB', value: 'Full Board'},
        {key: 'AI', value: 'All Inclusive'},
      ],
      currencyValue: 100,
      arrival: '',
      comments: '',
      reference: '',
      modalStatus: false,
      confirmModalStatus: false,
      rooms: Rooms.getRooms(),
      roomRates: [],
      total: 0,
      confirmEnabled: false,
      days: 0,
      specialRequests: '',
      termsModalStatus: false
    });
  };

  setFromDate = (date) => {
    let now = moment().format('YYYY-MM-DD');
    let currentDate = moment(date).format('YYYY-MM-DD');

    this.setInitialState();

    if (now > currentDate) {
      this.setState({fromDate: '', errors: ['Please select a valid future date!']});
    } else {
      this.setState({fromDate: date, errors: []});
    }
  };

  /**
   *
   * @param date
   */
  setToDate = (date) => {
    let now = moment().format('YYYY-MM-DD');
    let currentDate = moment(date).format('YYYY-MM-DD');
    let fromDate = moment(this.state.fromDate).format('YYYY-MM-DD');

    this.setInitialState();

    this.setState({fromDate: fromDate});

    if (now > currentDate || fromDate > currentDate) {
      this.setState({toDate: '', errors: ['Please select a valid future date!']});
    } else {
      this.setState({toDate: date}, () => {
        this.setState({errors: []});
        if (this.state.fromDate) {
          // this.sendDates();
        } else {
          this.setState({errors: ['Please select a valid future date for from date!']});
        }
      });
    }
  };

  /**
   *
   * @param count
   * @returns {boolean}
   */
  setAdultCount = (count) => {

    if (count <= 0 || count === "") {
      this.setState({adultCount: 0, errors: ['Please enter valid number of adults!']});
      return false;
    }
    this.setState({adultCount: count, errors: []});
  };

  /**
   *
   * @param count
   * @returns {boolean}
   */
  setChildrenCount = (count) => {
    if (count < 0 || count === "") {
      this.setState({childrenCount: 0, errors: ['Please enter valid number of children!']});
      return false;
    }
    this.setState({childrenCount: count, errors: []});
  };

  /**
   *
   * @param event
   */
  setTitle = (event) => {
    this.setField(event.target.value, 'title');
  };

  // setTitle = (value) => {
  //     this.setField(value, 'title');
  // };
  /**
   *
   * @param value
   */
  setFirstName = (value) => {
    this.setField(value, 'firstName');
  };

  /**
   *
   * @param value
   */
  setLastName = (value) => {
    this.setField(value, 'lastName');
  };

  /**
   *
   * @param value
   */
  setCity = (value) => {
    this.setField(value, 'city');
  };

  /**
   *
   * @param event
   */
  setCountry = (event) => {
    this.setField(event.target.value, 'country');
  };

  /**
   *
   * @param event
   */
  setNationality = (event) => {
    this.setField(event.target.value, 'nationality');
  };

  /**
   *
   * @param value
   */
  setEmail = (value) => {
    this.setField(value, 'email');
  };

  /**
   *
   * @param value
   */
  setConfirmEmail = (value) => {
    this.setField(value, 'confirmEmail');
  };

  /**
   *
   * @param value
   */
  setAddress = (value) => {
    this.setField(value, 'address');
  };

  /**
   *
   * @param value
   */
  setPostcode = (value) => {
    this.setField(value, 'postcode');
  };

  /**
   *
   * @param value
   */
  setTelephone = (value) => {
    this.setField(value, 'telephone');
  };

  /**
   *
   * @param value
   */
  setBookedBy = (value) => {
    this.setField(value, 'bookedBy');
  };

  /**
   *
   * @param value
   */
  setArrival = (value) => {
    this.setField(value, 'arrival');
  };

  /**
   *
   * @param event
   */
  setComments = (event) => {
    this.setField(event.target.value, 'comments');
  };

  /**
   *
   * @param value
   */
  setRoomType = (value) => {
    if (value.name) {
      let selectedCheck = this.state.selectedRoomTypes.find(selectedRoomType => {
        return selectedRoomType.name === value.name;
      });


      if (selectedCheck) {

        let selectedRoomTypes = this.state.selectedRoomTypes;
        selectedRoomTypes.forEach((selectedRoomType, index) => {
          if (selectedRoomType.name === value.name) {
            if (value.selected) {
              selectedRoomTypes[index].quantity = selectedRoomTypes[index].initQuantity;
              selectedRoomTypes[index].package = value.package;
              selectedRoomTypes[index].price = value.price;
              selectedRoomTypes[index].priceValue = value.priceValue;
            } else {
              selectedRoomTypes[index].quantity = 0;
            }
          }
        });

        this.setState({selectedRoomTypes: selectedRoomTypes});
      } else {
        let selectedRoomTypes = this.state.selectedRoomTypes;
        // value.quantity = 0;
        selectedRoomTypes.push(value);
        this.setState({selectedRoomTypes: selectedRoomTypes});
      }
    }

    this.setState({total: this.getTotal()});

  };

  /**
   *
   * @param value
   * @param field
   * @returns {boolean}
   */
  setField = (value, field) => {
    let fieldObject = {};
    if (!value) {
      fieldObject = {errors: ['Please enter valid ' + field + ' !']};
      fieldObject[field] = '';
      this.setState(fieldObject);
      return false
    }

    fieldObject[field] = value;
    this.setState(fieldObject);
  };

  /**
   *
   * @param event
   */
  setConfirmEnabled = (event) => {
    this.setState({confirmEnabled: event.target.checked});
  };

  /**
   *
   */
  setMobile = () => {
    return this.setState({mobile: false});
  };

  /**
   *
   * @param event
   */
  setSpecialRequests = (event) => {
    return this.setState({specialRequests: event.target.value});
  };

  //Actions
  /**
   *
   */
  sendDates() {

    let fromDate = moment(this.state.fromDate);
    let toDate = moment(this.state.toDate);

    let difference = toDate.diff(fromDate, 'days');

    if (!difference) {
      difference = 1;
    }

    this.setState({days: difference});
    this.setState({loader: true});

    axios.get(this.baseUrl + 'newCheckAvailableRoomGuestCount?frmdate=' + this.state.fromDate + '&todate=' + this.state.toDate)
      .then(response => {
        this.setState({searchResults: response.data, stage: 2, scrollTrigger: true});
        this.getRoomRates();
        this.getRoomDetails();
        this.setState({loader: false});

      }).catch(error => {
      console.error(error);
    });
  }

  /**
   *
   */
  openModal() {
    this.setState({modalStatus: true,});
  }

  /**
   *
   */
  closeModal() {
    this.setState({modalStatus: false, errors: []});
  }

  /**
   *
   */
  closeConfirmModal = () => {
    this.setState({confirmModalStatus: false});
  };

  /**
   *
   * @returns {boolean}
   */
  confirmBookingView = () => {

    let errors = [];

    if (!this.state.title) {
      errors.push('Please enter title!');
    }

    if (!this.state.firstName) {
      errors.push('Please enter first name!');
    }

    if (!this.state.lastName) {
      errors.push('Please enter last name!');
    }

    if (!this.state.telephone) {
      errors.push('Please enter telephone number!');
    }

    if (!this.state.city) {
      errors.push('Please enter city!');
    }

    if (!this.state.country) {
      errors.push('Please select country!');
    }

    if (!this.state.nationality) {
      errors.push('Please enter nationality!');
    }

    if (!this.state.email) {
      errors.push('Please enter email!');
    }

    if (!this.state.confirmEmail) {
      errors.push('Please enter confirm email!');
    }

    if (this.state.email !== this.state.confirmEmail) {
      errors.push('Email and confirm email does not match!');
    }

    if (!this.state.bookedBy) {
      errors.push('Please enter booked by person!');
    }
    if (!this.state.address) {
      errors.push('Please enter address!');
    }
    if (!this.state.postcode) {
      errors.push('Please enter postcode!');
    }

    // if (!this.state.arrival) {
    //     errors.push('Please enter arrival!');
    // }

    if (errors.length) {
      this.setState({errors: errors});
      return false;
    }

    this.closeModal();

    this.setState({confirmModalStatus: true, errors: []});
  };

  /**
   *
   */
  openTermsModal(event) {
    event.preventDefault();
    this.setState({termsModalStatus: true,});
  }

  /**
   *
   */
  closeTermsModal() {
    this.setState({termsModalStatus: false,});
  }

  /**
   *
   * @returns {boolean}
   */
  confirmBooking() {
    let referenceOrder = 'b_' + moment().format('YYDMHHmmss') + '_' + (Math.random() * 1000).toFixed();
    localStorage.setItem('efgr_data', JSON.stringify(this.state));
    this.loadPayment(referenceOrder);

  }

  /**
   *
   * @param order_id
   */
  loadPayment = (order_id) => {
    this.setState({loader: true});

    axios.get(`https://www.ellaflowergardenresort.com/datapg/`
      + `?order_id=${order_id}`
      + `&order_amount=${this.state.total}`
      + `&email=${this.state.email}`
      + `&first_name=${this.state.firstName}`)
      .then(response => {
        this.closeConfirmModal();
        let responseData = response.data;

        if (responseData) {
          let aScript = document.createElement('script');
          aScript.type = 'text/javascript';
          aScript.setAttribute("data-cancel", 'cancelCallback');
          aScript.src = "https://cbcmpgs.gateway.mastercard.com/checkout/version/52/checkout.js";

          document.head.appendChild(aScript);

          this.setState({loader: false});

          aScript.onload = function () {
            window.Checkout.configure({
              merchant: 'ELLAFLOWEUSD',
              order: {
                currency: 'USD',
                description: 'Room Bookings',
              },
              session: {id: responseData.session_id},
              interaction: {
                operation: 'AUTHORIZE', // set this field to 'PURCHASE' for Hosted Checkout to perform a Pay Operation.
                merchant: {
                  name: 'ELLAFLOWEUSD',
                  // address: {
                  // line1: '200 Sample St',
                  // line2: '1234 Example Town'
                  // }
                },
                displayControl: {
                  billingAddress: 'HIDE',
                  customerEmail: 'OPTIONAL',
                  orderSummary: 'SHOW',
                  shipping: 'HIDE'
                }
              }
            });

            window.Checkout.showLightbox();
          }
        }
      }).catch(error => {
      console.error(error);
    });
  };
  cancelCallback = () => {
    this.setState({success: []});
  };
}

window.cancelCallback = () => {
  new Slider().setState({success: []});
};

export default Slider;
