import React, {Component} from "react";
import Header from '../page-components/Header'
import Footer from '../page-components/Footer'
import Slider from "../page-components/Slider";
import {CSSTransition} from "react-transition-group";
import HelmetExport from "react-helmet/es/Helmet";

class Gallery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lightBoxImage: '',
            lightBoxStatus: false,
            gallery: [
                "assets/images/Gallery/3S1A7756.jpg",
                "assets/images/Gallery/3S1A7777.jpg",
                "assets/images/Gallery/3S1A7813.jpg",
                "assets/images/Gallery/3S1A7829.jpg",
                "assets/images/Gallery/3S1A7831.jpg",
                "assets/images/Gallery/3S1A7832.jpg",
                "assets/images/Gallery/3S1A7834.jpg",
                "assets/images/Gallery/3S1A7836.jpg",
                "assets/images/Gallery/3S1A7843.jpg",
                "assets/images/Gallery/3S1A7846.jpg",
                "assets/images/Gallery/3S1A7856.jpg",
                "assets/images/Gallery/3S1A7995.jpg",
                "assets/images/Gallery/3S1A8093.jpg",
                "assets/images/Gallery/3S1A8123.jpg",
                "assets/images/Gallery/3S1A8137.jpg",
                "assets/images/Gallery/3S1A8167.jpg",
                "assets/images/Gallery/3S1A8196.jpg",
                "assets/images/Gallery/3S1A8197.jpg",
                "assets/images/Gallery/3S1A8209.jpg",
                "assets/images/Gallery/3S1A8220.jpg",
                "assets/images/Gallery/3S1A8226.jpg",
                "assets/images/Gallery/3S1A8229.jpg",
                "assets/images/Gallery/39700522_1824689370901478_4438090673047994368_o.jpg",
                "assets/images/Gallery/40766686_1846129848757430_6057626323864518656_o.jpg",
                "assets/images/Gallery/40766768_1846130358757379_5358533139884933120_o.jpg",
                "assets/images/Gallery/58729761_2178695025500909_2249507117192970240_o.jpg",
                "assets/images/Gallery/59079232_2178698192167259_9012156699267563520_o.jpg",
                "assets/images/Gallery/59093798_2178694708834274_924053705404186624_o.jpg",
                "assets/images/Gallery/59285852_2178698058833939_556309311211438080_o.jpg",
                "assets/images/Gallery/60035537_2192674857436259_6897299967900123136_o.jpg",
                "assets/images/Gallery/81762637_2672614359442304_4667405247633686528_n.jpg",
                "assets/images/Gallery/81792765_2672614939442246_1128809158775144448_n.jpg",
                "assets/images/Gallery/82261238_2672614706108936_8145260848135274496_n.jpg",
                "assets/images/Gallery/90198182_2829967950373610_1679126707140820992_n.jpg",
                "assets/images/Gallery/90234821_2829967923706946_6796287289195495424_n.jpg",
                "assets/images/Gallery/90255108_2829967743706964_4360333184961871872_n.jpg",
                "assets/images/Gallery/AJ2I6740.jpg",
                "assets/images/Gallery/AJ2I6811.jpg",
                "assets/images/Gallery/AJ2I6876.jpg",
                "assets/images/Gallery/AJ2I6885.jpg",
                "assets/images/Gallery/AJ2I6928.jpg",
                "assets/images/Gallery/AJ2I6941.jpg",
                "assets/images/Gallery/GOPR0012.JPG",
                "assets/images/Gallery/GOPR0013.JPG",
                "assets/images/Gallery/IMG_5362.jpg",
                "assets/images/Gallery/IMG_5626.jpg",
                "assets/images/Gallery/IMG_5642.jpg",
                "assets/images/Gallery/IMG_5862_edited.jpg",
                "assets/images/Gallery/IMG_5875.jpg",
                "assets/images/Gallery/IMG_5894.jpg",
                "assets/images/Gallery/IMG_5907.jpg",
                "assets/images/Gallery/IMG_8763.JPG",
                "assets/images/Gallery/IMG_8765.JPG",
                "assets/images/Gallery/IMG_8768.JPG",
                "assets/images/Gallery/n4.jpg",
                "assets/images/Gallery/n6.jpg",
                "assets/images/Gallery/slide2.jpg",
                "assets/images/Gallery/3S1A6714.jpg",
                "assets/images/Gallery/3S1A6788.jpg",
                "assets/images/Gallery/3S1A7026.jpg",
                "assets/images/Gallery/3S1A7718.jpg",
                "assets/images/Gallery/3S1A7734.jpg",
                "assets/images/Gallery/3S1A7736.jpg",
            ]
        };
    }

    componentDidMount() {
        var elements = document.querySelectorAll('.moments .col-sm-4');

        var max_height = 0;

        elements.forEach(function (element) {
            if (element.clientHeight > max_height) {
                max_height = element.clientHeight;
            }
        });

        elements.forEach(function (element) {
            element.style.height = max_height + 'px';
        });

        this.setState({lightBoxStatus: false});
    }

    render() {
        return <div>
            <HelmetExport>
                <title>Ella Flower Garden Resort | Gallery</title>
            </HelmetExport>
            <Header/>
            <Slider
                images={['assets/images/3S1A7756.jpg', 'assets/images/3S1A7756.jpg', 'assets/images/3S1A7756.jpg',]}/>

            <section className="container gallery-images">
                <div className="row">
                    <div className="col">
                        <div className="row">
                            {this.getGallery()}
                        </div>
                    </div>
                </div>
            </section>
            <CSSTransition
                in={this.state.lightBoxStatus}
                timeout={200} className="gallery-light-box">
                <div className="gallery-light-box" key="gallery-light-box">
                    <div className="gallery-light-box-close float-right">
                        <button type="button" onClick={this.closeLightBox.bind(this)}>X</button>
                    </div>
                    <img src={this.state.lightBoxImage} alt=""/>
                </div>
            </CSSTransition>
            <Footer/>
        </div>
    }

    openLightBox(image) {
        this.setState({lightBoxImage: image, lightBoxStatus: true,});
    }

    closeLightBox() {
        this.setState({lightBoxStatus: false,});
    }

    getGallery() {
        let gallery = [];

        this.state.gallery.forEach((gallery_item, index) => {
            gallery.push(<div key={index} className="col-12 col-sm-3">
                <button className="light-box-button" type="button"
                        onClick={this.openLightBox.bind(this, gallery_item)}>
                    <img alt={'gallery-item-' + index} src={gallery_item}/>
                </button>
            </div>)
        });

        return gallery
    }
}

export default Gallery;
