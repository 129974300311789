class Rooms {
    static getRooms = () => {
        return [
            {
                title: 'Delux Suite Cabana With Private Pool',
                mapper: "DELUXE DOUBLE CABANA POOL ROOM",
                subtitle: 'Our Delux Suite Cabana With Private Pool',
                description: ' Delux Suite Cabana With Private Pool are specially made for have a super luxary experience. All rooms have a private pool with a beautiful view. Rooms available as double.',
                homeLogo: "assets/images/accommodation/Delux Suite Cabana With Private Pool/home-image.jpg",
                logo: "assets/images/accommodation/Delux Suite Cabana With Private Pool/home-image.jpg",
                homePage: true,
                images: [
                    "assets/images/accommodation/Delux Suite Cabana With Private Pool/AJ2I6740.jpg",
                    "assets/images/accommodation/Delux Suite Cabana With Private Pool/GPTempDownload(1).jpg",
                    "assets/images/accommodation/Delux Suite Cabana With Private Pool/GPTempDownload(2).jpg",
                    "assets/images/accommodation/Delux Suite Cabana With Private Pool/GPTempDownload(3).jpg",
                    "assets/images/accommodation/Delux Suite Cabana With Private Pool/GPTempDownload.jpg"
                ]
            },
            {
                title: 'Deluxe Double Room',
                mapper: "DELUXE DOUBLE ROOMS",
                subtitle: 'Our Delux Suite Room',
                description: ' Delux room are specially made for have a luxary experience. All rooms have a scenic view of Ella Rock. Rooms available as Double, triple or Family .',
                homeLogo: "assets/images/accommodation/Deluxe Double Room/IMG_5328.jpg",
                logo: "assets/images/accommodation/Deluxe Double Room/IMG_5328.jpg",
                homePage: true,
                images: [
                    "assets/images/accommodation/Deluxe Double Room/GPTempDownload(1).jpg",
                    "assets/images/accommodation/Deluxe Double Room/GPTempDownload.jpg",
                    "assets/images/accommodation/Deluxe Double Room/IMG_1352.jpg",
                    "assets/images/accommodation/Deluxe Double Room/IMG_1422.jpg",
                    "assets/images/accommodation/Deluxe Double Room/IMG_5328.jpg",
                    "assets/images/accommodation/Deluxe Double Room/IMG_5374.jpg"
                ]
            },
            {
                title: 'Deluxe Triple Room',
                mapper: "DELUXE TRIPLE ROOMS",
                subtitle: 'Our Delux Suite Room',
                description: ' Delux room are specially made for have a luxary experience. All rooms have a scenic view of Ella Rock. Rooms available as Double, triple or Family .',
                homeLogo: "assets/images/accommodation/Deluxe Triple Room/GPTempDownload(2).jpg",
                logo: "assets/images/accommodation/Deluxe Triple Room/GPTempDownload(2).jpg",
                homePage: false,
                images: [
                    "assets/images/accommodation/Deluxe Triple Room/GPTempDownload(1).jpg",
                    "assets/images/accommodation/Deluxe Triple Room/GPTempDownload(2).jpg",
                    "assets/images/accommodation/Deluxe Triple Room/GPTempDownload.jpg",
                    "assets/images/accommodation/Deluxe Triple Room/IMG_1350.jpg",
                    "assets/images/accommodation/Deluxe Triple Room/IMG_5310.jpg"
                ]
            },
            {
                title: 'Deluxe Family Room',
                mapper: "DELUXE FAMILY ROOM",
                subtitle: 'Our Delux Suite Room',
                description: ' Delux room are specially made for have a luxary experience. All rooms have a scenic view of Ella Rock. Rooms available as Double, triple or Family .',
                homeLogo: "assets/images/accommodation/Deluxe Family Room/IMG_1427.jpg",
                logo: "assets/images/accommodation/Deluxe Family Room/IMG_1427.jpg",
                homePage: false,
                images: [
                    "assets/images/accommodation/Deluxe Family Room/GPTempDownload(1).jpg",
                    "assets/images/accommodation/Deluxe Family Room/GPTempDownload.jpg",
                    "assets/images/accommodation/Deluxe Family Room/IMG_1427.jpg",
                    "assets/images/accommodation/Deluxe Family Room/IMG_1435-2.jpg",
                    "assets/images/accommodation/Deluxe Family Room/IMG_5774.jpg"
                ]
            },
            {
                title: 'Superior Double Room',
                mapper: "SUPERIOR DOUBLE ROOM",
                subtitle: 'Our Superior Room',
                description: ' Superior rooms are specially made for have a luxary experience. All rooms have a scenic view of Ella Rock. Rooms available as double , triple or family.',
                homeLogo: 'assets/images/accommodation/Superior Double Room/Superior-room-picture.jpg',
                logo: "assets/images/accommodation/Superior New Room/AJ2I6921.jpg",
                homePage: true,
                images: [
                    "assets/images/accommodation/Superior Double Room/3S1A7986.jpg",
                    "assets/images/accommodation/Superior Double Room/3S1A7996.jpg",
                    "assets/images/accommodation/Superior Double Room/3S1A8001.jpg"
                ]
            },
            {
                title: 'Superior Triple Room',
                mapper: "SUPERIOR TRIPLE ROOM",
                subtitle: 'Our Superior Room',
                description: ' Superior rooms are specially made for have a luxary experience. All rooms have a scenic view of Ella Rock. Rooms available as double , triple or family.',
                homeLogo: "assets/images/accommodation/Superior Triple Room/CCC_5633.jpg",
                logo: "assets/images/accommodation/Superior Triple Room/CCC_5633.jpg",
                homePage: false,
                images: [
                    "assets/images/accommodation/Superior Triple Room/3S1A7965.jpg",
                    "assets/images/accommodation/Superior Triple Room/CCC_5618.jpg",
                    "assets/images/accommodation/Superior Triple Room/CCC_5621.jpg",
                    "assets/images/accommodation/Superior Triple Room/CCC_5633.jpg"
                ]
            },
            {
                title: 'Superior Family Room',
                mapper: "SUPERIOR FAMILY ROOM",
                subtitle: 'Our Superior Room',
                description: ' Superior rooms are specially made for have a luxary experience. All rooms have a scenic view of Ella Rock. Rooms available as double , triple or family.',
                homeLogo: "assets/images/accommodation/Superior Family Room/3S1A7976.jpg",
                logo: "assets/images/accommodation/Superior Family Room/3S1A7976.jpg",
                homePage: false,
                images: [
                    "assets/images/accommodation/Superior Family Room/3S1A7912.jpg",
                    "assets/images/accommodation/Superior Family Room/3S1A7918.jpg",
                    "assets/images/accommodation/Superior Family Room/3S1A7976.jpg",
                    "assets/images/accommodation/Superior Family Room/3S1A7979.jpg",
                    "assets/images/accommodation/Superior Family Room/3S1A7997.jpg",
                    "assets/images/accommodation/Superior Family Room/3S1A7998.jpg"
                ]
            },
            {
                title: 'Superior New Room',
                mapper: "SUPERIOR DOUBLE MOUNTAIN VIEW 27",
                subtitle: 'Our Superior New Room',
                description: ' Superior rooms are specially made for have a luxary experience. All rooms have a scenic view of Ella Rock. Rooms available as double , triple or family.',
                homeLogo: "assets/images/accommodation/Superior New Room/AJ2I6921.jpg",
                logo: "assets/images/accommodation/Superior Double Room/3S1A8001.jpg",
                homePage: true,
                images: [
                    "assets/images/accommodation/Superior New Room/AJ2I6921.jpg",
                    "assets/images/accommodation/Superior New Room/AJ2I6928.jpg",
                    "assets/images/accommodation/Superior New Room/AJ2I6932.jpg",
                    "assets/images/accommodation/Superior New Room/GPTempDownload(1).jpg",
                    "assets/images/accommodation/Superior New Room/GPTempDownload.jpg"
                ]
            },
            {
                title: 'Superior Small Room',
                mapper: "SUPERIOR SMALL TWIN 101",
                subtitle: 'Our Superior Room',
                description: ' Superior rooms are specially made for have a luxary experience. All rooms have a scenic view of Ella Rock. Rooms available as double , triple or family.',
                homeLogo: "assets/images/accommodation/Superior Small Room/GPTempDownload(2).jpg",
                logo: "assets/images/accommodation/Superior Small Room/GPTempDownload(2).jpg",
                homePage: false,
                images: [
                    "assets/images/accommodation/Superior Small Room/GPTempDownload(1).jpg",
                    "assets/images/accommodation/Superior Small Room/GPTempDownload(2).jpg",
                    "assets/images/accommodation/Superior Small Room/GPTempDownload(3).jpg",
                    "assets/images/accommodation/Superior Small Room/GPTempDownload.jpg"
                ]
            },
            {
                title: 'Chalet Double Room',
                mapper: "CHALET DOUBLE ROOM",
                subtitle: 'Our Chalet Rooms',
                description: 'Chalets are seperate cottage and it\'s surrounded by trees with the view of Ella Gap. Rooms are large and have more privacy.',
                homeLogo: 'assets/images/5.jpg',
                logo: 'assets/images/5.jpg',
                homePage: true,
                images: [
                    "assets/images/accommodation/Chalet Double Room/3S1A8160.jpg",
                    // "assets/images/accommodation/Chalet Double Room/3S1A8166.jpg",
                    "assets/images/accommodation/Chalet Double Room/3S1A8194.jpg",
                    "assets/images/accommodation/Chalet Double Room/5.jpg"
                ]
            },
            {
                title: 'Chalet Triple Room',
                mapper: "CHALET TRIPLE ROOM",
                subtitle: 'Our Chalet Rooms',
                description: 'Chalets are seperate cottage and it\'s surrounded by trees with the view of Ella Gap. Rooms are large and have more privacy.',
                homeLogo: "assets/images/accommodation/Chalet Triple Room/3S1A8169.jpeg",
                logo: "assets/images/accommodation/Chalet Triple Room/3S1A8169.jpeg",
                homePage: false,
                images: [
                    "assets/images/accommodation/Chalet Triple Room/3S1A8169.jpeg",
                    "assets/images/accommodation/Chalet Triple Room/3S1A8191.jpg",
                    // "assets/images/accommodation/Chalet Triple Room/GPTempDownload(1).jpg",
                    "assets/images/accommodation/Chalet Triple Room/GPTempDownload.jpg"
                ]
            },
            {
                title: 'Chalet Family Room',
                mapper: "CHALET FAMILY ROOM",
                subtitle: 'Our Chalet Rooms',
                description: 'Chalets are seperate cottage and it\'s surrounded by trees with the view of Ella Gap. Rooms are large and have more privacy.',
                homeLogo: "assets/images/accommodation/Chalet Family Room/3S1A8169.jpg",
                logo: "assets/images/accommodation/Chalet Family Room/3S1A8169.jpg",
                homePage: false,
                images: [
                    "assets/images/accommodation/Chalet Family Room/3S1A8164.jpg",
                    "assets/images/accommodation/Chalet Family Room/3S1A8169.jpg",
                    "assets/images/accommodation/Chalet Family Room/3S1A8191.jpg",
                    "assets/images/accommodation/Chalet Family Room/GPTempDownload.jpg"
                ]
            },
            {
                title: 'Standard Double Rooms',
                mapper: "STANDARD DOUBLE",
                subtitle: 'Our Standard Room ',
                description: 'Standard rooms available for budget stay. It\'s not comfortable as delux. But you can have a safe stay without any disturbances. Rooms are available as double,triple and family.',
                homeLogo: 'assets/images/GPTempDownload.jpg',
                logo: 'assets/images/GPTempDownload.jpg',
                homePage: true,
                images: [
                    "assets/images/accommodation/Standard Double Rooms/GPTempDownload(1).jpg",
                    "assets/images/accommodation/Standard Double Rooms/GPTempDownload(2).jpg",
                    "assets/images/accommodation/Standard Double Rooms/GPTempDownload(3).jpg",
                    "assets/images/accommodation/Standard Double Rooms/GPTempDownload(4).jpg",
                    "assets/images/accommodation/Standard Double Rooms/GPTempDownload.jpg"
                ]
            },
            {
                title: 'Standard Triple Rooms',
                mapper: "STANDARD TRIPLE ROOMS",
                subtitle: 'Our Standard Room ',
                description: 'Standard rooms available for budget stay. It\'s not comfortable as delux. But you can have a safe stay without any disturbances. Rooms are available as double,triple and family.',
                homeLogo: "assets/images/accommodation/Standard Triple Rooms/IMG_1503.jpg",
                logo: "assets/images/accommodation/Standard Triple Rooms/IMG_1503.jpg",
                homePage: false,
                images: [
                    "assets/images/accommodation/Standard Triple Rooms/IMG_1496.jpg",
                    "assets/images/accommodation/Standard Triple Rooms/IMG_1498.jpg",
                    "assets/images/accommodation/Standard Triple Rooms/IMG_1499.jpg",
                    "assets/images/accommodation/Standard Triple Rooms/IMG_1503.jpg"
                ]
            },
            {
                title: 'Standard Family Rooms',
                mapper: "STANDARD FAMILY ROOMS",
                subtitle: 'Our Standard Room ',
                description: 'Standard rooms available for budget stay. It\'s not comfortable as delux. But you can have a safe stay without any disturbances. Rooms are available as double,triple and family.',
                homeLogo: "assets/images/accommodation/Standard Family Rooms/3S1A8047.jpg",
                logo: "assets/images/accommodation/Standard Family Rooms/3S1A8047.jpg",
                homePage: false,
                images: [
                    "assets/images/accommodation/Standard Family Rooms/3S1A8045.jpg",
                    "assets/images/accommodation/Standard Family Rooms/3S1A8047.jpg",
                    "assets/images/accommodation/Standard Family Rooms/GPTempDownload(1).jpg",
                    "assets/images/accommodation/Standard Family Rooms/GPTempDownload.jpg"
                ]
            },
        ];
    }
}

export default Rooms;
