import React from 'react';

import {Router} from "@reach/router";

import Home from "./pages/Home";
import Restaurant from "./pages/Restaurant";

import "./assets/css/bootstrap.min.css";
import "./assets/css/all.min.css";
import "./assets/css/style.css";
import Location from "./pages/Location";
import Accommodation from "./pages/Accommodation";
import ThingsToDo from './pages/ThingsToDo'
import SpecialOffers from "./pages/SpecialOffers";
import Gallery from "./pages/Gallery";
import ContactUs from "./pages/ContactUs";
import {ScrollToTop} from "./page-components/ScrollToTop";
import Success from "./pages/Success";
import Terms from "./pages/Terms";

function App() {
    return (
        <Router primary={false}>
            <ScrollToTop path="/">
                <Home path="/"/>
                <Restaurant path="/restaurant"/>
                <Location path="/location"/>
                <Accommodation path="/accommodation"/>
                <ThingsToDo path="/things-to-do"/>
                <SpecialOffers path="/special-offers"/>
                <Gallery path="/gallery"/>
                <ContactUs path="/contact-us"/>
                <Success path="/success/:orderId/:orderAmount/:email/:firstName"/>
                <Terms path="/terms-and-conditions"/>
            </ScrollToTop>
        </Router>
    );
}

export default App;
