import React, {Component} from "react";
import Header from '../page-components/Header'
import Footer from '../page-components/Footer'
import HelmetExport from "react-helmet/es/Helmet";
import {Link} from "@reach/router";

/**
 *
 */
class Terms extends Component {


    /**
     *
     * @returns {*}
     */
    render() {

        return <div>
            <HelmetExport>
                <title>Ella Flower Garden Resort</title>
            </HelmetExport>
            <Header/>
            <section className="container about-us" style={{'padding-top': '200px'}}>
                <div className="row">
                    <div className="col text-center">
                        <h1>ELLA SRI LANKA</h1>
                        <h2>Terms and Conditions</h2>
                        <div className="col description">
                            Terms and conditions goes here.
                        </div>
                        <div className="col back">
                            <Link to="/"><i className="fas fa-home"></i> Go back to home page</Link>
                        </div>
                        <hr/>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    }
}

/**
 *
 */
export default Terms;
