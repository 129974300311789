import React, {Component} from "react";

class NumberInput extends Component {


    render() {
        return <input className="form-control" type="number" min={this.props.min}
                      max={this.props.max ? this.props.max : 1000} placeholder={this.props.placeholder}
                      step={this.props.step} value={this.props.value}
                      defaultValue={this.props.defaultValue ? this.props.defaultValue : 0}
                      onChange={this.setNumber.bind(this)}/>
    }

    setNumber(event) {
        this.props.setNumber(event.target.value);
    }
}

export default NumberInput;
