import React, {Component} from "react";

/**
 *
 */
class SearchResult extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            packageDisabled: true,
            roomType: {
                name: '',
                package: '',
                id: ''
            },
            price: '',
            priceValue: 0,
            quantity: 1,
            checked: false
        };
    }

    /**
     *
     */
    componentDidMount() {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        this.setState({price: formatter.format(0)});

    }

    /**
     *
     * @return {*}
     */
    render() {
        return <div className=" col-12 col-sm-4">
            <div className={"col results-wrapper " + (this.state.checked ? "selected" : "")}>
                <h2>{this.props.result.avalRoomtype}</h2>
                {this.props.room ?
                    <img src={this.props.room.logo} alt="" className="img-fluid"/>
                    :
                    <img src="assets/images/default.jpg" alt="" className={'img-fluid'}/>
                }
                <div className="col details table-responsive">
                    <table className="table table-borderless">
                        <tbody>
                        <tr>
                            <td>
                                Available Date:
                            </td>
                            <td>
                                {this.props.result.avldate}
                            </td>
                        </tr>
                        <tr>
                            <td>Availability:</td>
                            <td>{this.props.result.avalability > 0 ? this.props.result.avalability :
                                <span className="help-block">Not Available</span>}</td>
                        </tr>
                        <tr>
                            <td>Max Number of Guests:</td>
                            <td>{this.props.result.maxNoOfGuest}</td>
                        </tr>
                        <tr>
                            <td>Rooms:</td>
                            <td>
                                <select onChange={this.setQuantity.bind(this)}>
                                    {this.getQuantity()}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Select Package:</td>
                            <td>
                                <select className="form-control"
                                        onChange={this.setPackage.bind(this, this.props.result.avalRoomtype)}>
                                    {this.getPackages()}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Select Room:
                            </td>
                            <td className="text-right">
                                <input type="checkbox" disabled={this.state.packageDisabled}
                                       checked={this.state.checked}
                                       onChange={this.setRoomType.bind(this, this.props.result.avalRoomtype, this.props.result.id)}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="text-right price">
                                {this.state.price}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    };

    /**
     *
     * @param roomType
     * @param id
     * @param event
     */
    setRoomType = (roomType, id, event) => {
        let state = {};
        let previousState = this.state.roomType;

        let selected = false;

        if (event && event.target.checked) {
            selected = event.target.checked;
        }

        state = {
            roomType: {
                name: roomType,
                id: id,
                package: previousState.package,
                selected: selected,
                price: this.state.price,
                priceValue: this.state.priceValue,
                quantity: this.state.quantity,
                initQuantity: this.state.quantity
            },
        };

        this.setState(state, () => {
            this.props.setRoomType(state.roomType);
        });

        this.setState({checked: true});
    };

    /**
     *
     * @returns {*[]}
     */
    getPackages = () => {
        let packages = [<option key={0} value="">Select</option>];

        this.props.packages.forEach((packageItem, index) => {
            packages.push(<option key={index + 1} value={packageItem.key}>{packageItem.value}</option>)
        });

        return packages;
    };
    /**
     *
     * @returns {[]}
     */
    getQuantity = () => {
        let quantities = [];


        for (let i = 1; i <= this.props.result.avalability; i++) {
            quantities.push(<option key={i} value={i}>{i}</option>);
        }


        return quantities;
    };

    /**
     *
     * @param {*} roomType
     * @param {*} event
     */
    setPackage = (roomType, event) => {

        let previousState = this.state.roomType;

        let targetValue = event.target.value;

        this.setState({
            roomType: {
                name: previousState.name,
                id: previousState.id,
                package: event.target.value,
            },
        }, () => {

            if (targetValue) {
                this.setState({packageDisabled: false});
            } else {
                this.setState({packageDisabled: true});
            }

            let ratesOfRoom = this.props.ratesOfRoom;

            let rate = ratesOfRoom.find(rateOfRoom => {
                return rateOfRoom.foodPackage === targetValue;
            });
            if (rate) {
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });


                this.setState({price: formatter.format(rate.roomRate), priceValue: rate.roomRate});

            }

            this.setRoomType(this.state.roomType.name, null);
            this.setState({checked: false});

        });


    };

    /**
     *
     * @param {*} event
     */
    setQuantity = (event) => {
        this.setState({quantity: event.target.value});
    };

}

export default SearchResult;
