import React, {Component} from "react";
import Header from '../page-components/Header'
import Footer from '../page-components/Footer'
import HelmetExport from "react-helmet/es/Helmet";
import axios from "axios";
import moment from "moment";

/**
 *
 */
class Success extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.baseUrl = 'https://itesholdings.com/OnlineResAPI/';
    }


    /**
     *
     */
    componentDidMount() {

        let store = JSON.parse(localStorage.getItem('efgr_data'));

        let donePromise = new Promise(((resolve, reject) => {
            let count = 0;
            store.selectedRoomTypes.forEach(selectedRoomType => {
                let reference = 'b_' + moment().format('YYDMHHmmss') + '_' + (Math.random() * 1000).toFixed();

                axios.get(this.baseUrl + 'reservRes/?jsarr=' +
                    store.title + '~~~' +
                    store.firstName + '~~~' +
                    store.lastName + '~~~' +
                    store.city + '~~~' +
                    store.country + '~~~' +
                    store.email + '~~~' +
                    store.telephone + '~~~' +
                    store.nationality + '~~~' +
                    store.fromDate + '~~~' +
                    store.toDate + '~~~' +
                    selectedRoomType.id + '~~~' +
                    store.adultCount + '~~~' +
                    store.childrenCount + '~~~' +
                    store.bookedBy + '~~~' +
                    selectedRoomType.package + '~~~' +
                    store.currencyValue + '~~~' +
                    selectedRoomType.package + '~~~' + //replaced from arrival
                    store.comments + '~~~' +
                    reference)
                    .then(response => {
                        this.setState({success: ['Booking request confirmed!']});
                        count++;
                        if (count === store.selectedRoomTypes.length) {
                            resolve(count);
                        }
                    })
                    .catch(error => {
                        this.setState({error: ['Booking request failed! Please try again!']});
                    });
            });
        }));

        donePromise.then(count => {
            axios.get(`https://www.ellaflowergardenresort.com/datapg/mail.php`
              + `?order_id=${this.props.orderId}`
              + `&order_amount=${this.props.orderAmount}`
              + `&email=${this.props.email}`
              + `&lastName=${store.lastName}`
              + `&title=${store.title}`
              + `&fromDate=${store.fromDate}`
              + `&toDate=${store.toDate}`
              + `&adults=${store.adultCount}`
              + `&children=${store.childrenCount}`
              + `&package=${store.selectedRoomTypes[0].package}`
                + `&city=${store.city}`
                + `&country=${store.country}`
                + `&nationality=${store.nationality}`
                + `&telephone=${store.telephone}`
                + `&bookedBy=${store.bookedBy}`
                + `&address=${store.address}`
                + `&postcode=${store.postcode}`
                + `&comments=${store.comments}`
                + `&specialRequests=${store.specialRequests}`
                + `&roomType=${store.selectedRoomTypes[0].name}`
                + `&firstName=${this.props.firstName}`)
                .then(response => {
                    setTimeout(() => {
                        window.location.href = 'https://www.ellaflowergardenresort.com';
                    }, 5000);

                    localStorage.clear();

                    // sessionStorage.clear();

                })
                .catch(error => {
                    console.log(error);
                })
        });
    }

    /**
     *
     * @returns {*}
     */
    render() {

        return <div>
            <HelmetExport>
                <title>Ella Flower Garden Resort</title>
            </HelmetExport>
            <Header/>
            <section className="container about-us" style={{'padding-top': '200px'}}>
                <div className="row">
                    <div className="col text-center">
                        <h1>ELLA SRI LANKA</h1>
                        <h2>BOOKING CONFIRMED</h2>
                        <div className="col description">
                            Your booking is confirmed. You will receive the detailed email shortly.
                        </div>
                        <hr/>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    }
}

/**
 *
 */
export default Success;
