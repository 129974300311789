import React, {Component} from "react";
import Header from '../page-components/Header'
import Footer from '../page-components/Footer'
import Slider from "../page-components/Slider";
import MiniSlider from "../page-components/MiniSlider";
import HelmetExport from "react-helmet/es/Helmet";

class Restaurant extends Component {


    render() {
        return <div>
            <HelmetExport>
                <title>Ella Flower Garden Resort | Restaurant</title>
            </HelmetExport>
            <Header/>
            <Slider
                images={['assets/images/restaurant-slider-1.jpg', 'assets/images/restaurant-slider-1.jpg', 'assets/images/restaurant-slider-1.jpg',]}/>
            <section className="container-fluid restaurant">
                <div className="row">
                    <div className="col col-sm-6">
                        <MiniSlider
                            images={['assets/images/restaurant/restaurant-1.jpg', 'assets/images/restaurant/restaurant-2.jpg', 'assets/images/restaurant/restaurant-3.jpg', 'assets/images/restaurant/restaurant-4.jpg',]}/>
                    </div>
                    <div className="col col-sm-6 align-self-center text-center restaurant-description">
                        <h3 className="col">DISCOVER OUR</h3>
                        <h2 className="col">RESTAURANT</h2>
                        <div className="col description">Our restaurant is one of the most iconic symbols which is grown
                            with Ella Flower Garden Resort. This is an excellent choice of restaurant offering various
                            Sri Lankan cousins as well as international meals. With the view of Ella Gap and tea estate
                            offer you a clam and relaxed dining experience of most delicious food which is unparalleled.
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    }
}

export default Restaurant;
