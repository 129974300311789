import React, {Component} from "react";
import Header from '../page-components/Header'
import Footer from '../page-components/Footer'
import Slider from "../page-components/Slider";
import HelmetExport from "react-helmet/es/Helmet";

class Location extends Component {


    render() {
        return <div>
            <HelmetExport>
                <title>Ella Flower Garden Resort | Location</title>
            </HelmetExport>
            <Header/>
            <Slider
                images={['assets/images/locationslider.jpg', 'assets/images/locationslider.jpg', 'assets/images/locationslider.jpg',]}/>
            <section className="container-fluid restaurant">
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <img src="assets/images/location-1.jpg" alt="" className="img-fluid"/>
                    </div>
                    <div className="col-12 col-sm-6 align-self-center text-center restaurant-description">
                        {/*<h3 className="col">LOREM IPSUM</h3>*/}
                        <h2 className="col">ELLA SRI LANKA</h2>
                        <div className="col description">Ella Flower Garden Resort , Its an Eco Friendly Resort which is
                            situated In Ella near Little Adam's peak. Its gives you what is true nature is , Full of
                            Birds and Flowers .Ella Flower Garden Resort is a resort which is very beautiful with a
                            Stunning View and a
                            <br/>
                            <br/>
                            Luxurious Accommodation with Private Pools.
                            <br/>
                            <br/>

                            Flower Garden Resort is a well-known Traveller's stop in Ella because of many
                            reasons. The
                            panoramic mountain view of Ella Rock is the most interesting sight you going to have
                            .
                            unbelievable and it could be the most beautiful thing you going to experience in
                            your
                            vacation in Sri Lanka. We are located just 65km away from Mattala International
                            Airport.

                            <br/>
                            <br/>
                            We have the most luxurious accommodation with all the facilities and restaurant
                            which
                            provides local cuisines as well as the international favorites with the view of
                            mountain
                            range in the outdoor sun deck. We also doing tours and provides transportation
                            facilities
                            which suitable for you to make your vacation a perfect one.
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 order-sm-6 col-sm-6">
                        <img src="assets/images/location-2.jpg" alt="" className="img-fluid"/>
                    </div>
                    <div className="col-12 col-sm-6 align-self-center text-center restaurant-description">
                        <h3 className="col">DISCOVER OUR</h3>
                        <h2 className="col">TOUR & TRANSPORT SERVICE</h2>
                        <div className="col description">Our vehicle fleet consists of luxury vans, cars and tuk tuks
                            also. We can take you to your next destination, pick up or drop off you to the airport. Not
                            only that we have some readymade tours all around the country as well as one day excursions.
                            With our experienced tour operators you can customize your tour as you want. We have well
                            talented drivers and guides who can take care of your safety throughout the journey.
                        </div>
                    </div>


                </div>
            </section>
            <Footer/>
        </div>
    }
}

export default Location;
