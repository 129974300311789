import React, {Component} from "react";
import {Link} from "@reach/router";

class NavLink extends Component {
    render() {
        return <Link
            {...this.props}
            getProps={({isCurrent}) => {
                return {
                    className: isCurrent ? this.props.className + " active" : this.props.className + " nav-link"
                };
            }}
        />
    }
}

export default NavLink;
