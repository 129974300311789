import React, {Component} from "react";
import Header from '../page-components/Header'
import Footer from '../page-components/Footer'
import Slider from "../page-components/Slider";
import MiniSlider from "../page-components/MiniSlider";
import HelmetExport from "react-helmet/es/Helmet";
import Rooms from "../data/Rooms";

class Accommodation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rooms: Rooms.getRooms()
        };
    }

    render() {
        return <div>

            <HelmetExport>
                <title>Ella Flower Garden Resort | Accommodation</title>
            </HelmetExport>
            <Header/>
            <Slider
                images={['assets/images/accommodation-slider-1.jpg', 'assets/images/accommodation-slider-1.jpg', 'assets/images/accommodation-slider-1.jpg',]}/>
            <section className="container-fluid restaurant">
                {this.getRooms()}
            </section>
            <Footer/>
        </div>
    }

    getRooms() {

        let rooms = [];

        this.state.rooms.forEach((room, index) => {
            if (index % 2 === 0) {
                rooms.push(<div className="row" key={index}>
                    <div className="col-12 col-sm-6">
                        <MiniSlider
                            images={room.images}/>
                    </div>
                    <div className="col-12 col-sm-6 align-self-center text-center restaurant-description">
                        <h3 className="col">{room.title}</h3>
                        <h2 className="col">{room.subtitle}</h2>
                        <div className="col description">{room.description}</div>
                    </div>
                </div>);
            } else {
                rooms.push(<div className="row" key={index}>
                    <div className="col-12 col-sm-6 order-sm-6">
                        <MiniSlider
                            images={room.images}/>
                    </div>
                    <div className="col-12 col-sm-6 align-self-center text-center restaurant-description">
                        <h3 className="col">{room.title}</h3>
                        <h2 className="col">{room.subtitle}</h2>
                        <div className="col description">{room.description}</div>
                    </div>
                </div>);
            }
        });

        return rooms;
    }
}


export default Accommodation;
