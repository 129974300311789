import React, {Component} from "react";

import {CSSTransition} from 'react-transition-group'; // ES6

class Success extends Component {

    render() {
        // if (this.props.success.length) {
        return <CSSTransition
            in={this.getSuccessStatus(this.props.success.length)}
            timeout={200}>
            <div className="alert alert-success">
                <ul>{this.getSuccess(this.props.success)}</ul>
            </div>
        </CSSTransition>
        // } else {
        //     return '';
        // }
    }

    getSuccess(success) {
        let success_html = [];
        success.forEach(function (item, index) {
            success_html.push(<li key={index}>{item}</li>);
        });
        return success_html;
    }

    getSuccessStatus(length) {
        if (length <= 0) {
            return false;
        } else {
            return true;
        }
    }
}

export default Success;
