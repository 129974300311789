import React, {Component} from "react";
import Header from '../page-components/Header'
import Footer from '../page-components/Footer'
import Slider from "../page-components/Slider";
import HelmetExport from "react-helmet/es/Helmet";
import {CSSTransition} from "react-transition-group";
import axios from 'axios';

class SpecialOffers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            offer: {},
            lightBoxStatus: false,
            offers: [],
            baseUrl: 'https://www.ellaflowergardenresort.com/data/wp-json/wp/v2'
        };
    }

    componentDidMount() {
        var elements = document.querySelectorAll('.moments .col-sm-4');

        var max_height = 0;

        elements.forEach(function (element) {
            if (element.clientHeight > max_height) {
                max_height = element.clientHeight;
            }
        });

        elements.forEach(function (element) {
            element.style.height = max_height + 'px';
        });

        var height_elements = document.querySelectorAll('.experiences .row .col .row .col .col');

        var element_max_height = 0;

        height_elements.forEach(function (height_element) {
            if (height_element.clientHeight > element_max_height) {
                element_max_height = height_element.clientHeight;
            }
        });

        height_elements.forEach(function (height_element) {
            var element_height = element_max_height + 20;
            height_element.style.height = element_height + 'px';
        });

        var h2_height_elements = document.querySelectorAll('.experiences .row .col .row .col h2');

        var h2_element_max_height = 0;

        h2_height_elements.forEach(function (h2_height_element) {
            if (h2_height_element.clientHeight > h2_element_max_height) {
                h2_element_max_height = h2_height_element.clientHeight;
            }
        });

        h2_height_elements.forEach(function (h2_height_element) {
            h2_height_element.style.height = h2_element_max_height + 'px';
        });

        axios.get(this.state.baseUrl + '/offers').then(response => {
            let offers = [];
            let dataPromise = new Promise(((resolve, reject) => {

                let count = 0;

                response.data.forEach(datum => {

                    let offer = {
                        id: datum.id,
                        title: datum.title.rendered,
                        content: datum.content.rendered,
                        image: '',
                        offer: datum.meta.offer
                    };

                    axios.get(this.state.baseUrl + '/media/' + datum.featured_media).then(mediaResponse => {
                        count++;

                        offer.image = mediaResponse.data.guid.rendered;

                        offers.push(offer);

                        if (count === response.data.length) {
                            resolve(offers);
                        }

                    }).catch(error => {
                        console.error(error);

                        count++;

                        offers.push(offer);

                        if (count === response.data.length) {
                            resolve(offers);
                        }
                    });
                });
            }));

            dataPromise.then(offers => {
                this.setState({offers: offers});
            })

        }).catch(error => {
            console.error(error)
        });
    }

    render() {
        return <div>
            <HelmetExport>
                <title>Ella Flower Garden Resort | Special Offers</title>
            </HelmetExport>
            <Header/>
            <Slider
                images={['assets/images/special-offer-slider.jpg', 'assets/images/special-offer-slider.jpg', 'assets/images/special-offer-slider.jpg',]}/>

            <section className="container about-us">
                <div className="row">
                    <div className="col text-center">
                        <h1>ELLA SRI LANKA</h1>
                        <h2>SPECIAL OFFERS</h2>
                    </div>
                </div>
            </section>
            <section className="container experiences">
                <div className="row">
                    <div className="col">
                        <div className="row">
                            {this.getOffers()}
                        </div>
                    </div>
                </div>
            </section>
            <CSSTransition
                in={this.state.lightBoxStatus}
                timeout={200} className="gallery-light-box">
                <div className="gallery-light-box" key="gallery-light-box">
                    <div className="gallery-light-box-close float-right">
                        <button type="button" onClick={this.closeLightBox.bind(this)}>X</button>
                    </div>
                    <div className="readmore">
                        <div className="row">
                            <div className="col-sm-5">
                                <img src={this.state.offer.image} alt="" className="float-left"/>
                            </div>
                            <div className="col-sm-7">
                                <h2>{this.state.offer.title}</h2>
                                <span dangerouslySetInnerHTML={{__html: this.state.offer.content}}></span>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
            <Footer/>
        </div>
    }

    getOffers() {
        let offers = [];

        this.state.offers.forEach(offer => {
            offers.push(<div className="col-12 col-sm-3" key={offer.id}>
                <div className="offer-bubble"><span>{offer.offer}</span></div>
                <img src={offer.image} alt="" className="img-fluid"/>
                <h2 className="todo">{offer.title}</h2>
                <div className="col" dangerouslySetInnerHTML={{__html: this.getShortDescription(offer.content)}}></div>
                <button type="button" onClick={this.openLightBox.bind(this, offer)}>Read More</button>
            </div>)
        });

        return offers;
    }

    getShortDescription(content) {
        return content.substring(0, 150) + ' ...';
    }

    openLightBox(offer) {
        this.setState({offer: offer, lightBoxStatus: true,});
    }

    closeLightBox() {
        this.setState({lightBoxStatus: false,});
    }

}

export default SpecialOffers;
