import React, {Component} from "react";
import Header from '../page-components/Header'
import Footer from '../page-components/Footer'
import Slider from "../page-components/Slider";
import {Link} from "@reach/router";
import HelmetExport from "react-helmet/es/Helmet";
import Rooms from "../data/Rooms";

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rooms: Rooms.getRooms()
        };
    }

    componentDidMount() {
        var elements = document.querySelectorAll('.moments .col-sm-4');

        var max_height = 0;

        elements.forEach(function (element) {
            if (element.clientHeight > max_height) {
                max_height = element.clientHeight;
            }
        });

        elements.forEach(function (element) {
            element.style.height = max_height + 'px';
        });

        var height_elements = document.querySelectorAll('.experiences .row .col .row .col-12 .col');

        var element_max_height = 0;

        height_elements.forEach(function (height_element) {
            if (height_element.clientHeight > element_max_height) {
                element_max_height = height_element.clientHeight;
            }
        });

        height_elements.forEach(function (height_element) {
            var element_height = element_max_height + 20;
            height_element.style.height = element_height + 'px';
        });

        var h2_height_elements = document.querySelectorAll('.experiences .row .col .row .col-12 h2');

        var h2_element_max_height = 0;

        h2_height_elements.forEach(function (h2_height_element) {
            if (h2_height_element.clientHeight > h2_element_max_height) {
                h2_element_max_height = h2_height_element.clientHeight;
            }
        });

        h2_height_elements.forEach(function (h2_height_element) {
            h2_height_element.style.height = h2_element_max_height + 'px';
        });

    }

    render() {
        return <div>
            <HelmetExport>
                <title>Ella Flower Garden Resort</title>
            </HelmetExport>
            <Header/>
            <Slider images={[
                // 'assets/images/header.jpg',
                'assets/images/home-slider-2.jpg',
                'assets/images/home-slider-3.jpg',
                'assets/images/home-slider-4.jpg',
                'assets/images/home-slider-5.jpg',
                'assets/images/home-slider-1.jpg',
            ]}/>

            <section className="container about-us">
                <div className="row">
                    <div className="col text-center">
                        <h1>ELLA SRI LANKA</h1>
                        <h2>WELCOME TO FLOWER GARDEN RESORT</h2>
                        <div className="col description">
                            Ella is one of the most beautiful tourist destinations in Sri Lanka because of its climate
                            and the scenic beauty of nature. Ella Gap, Ravana waterfall and cave, Little Adams Peak are
                            some of the attractions in Ella. Not only that, you can have the experience of nature
                            trails, hiking and trekking through the tea plantation and the mountain range.
                        </div>
                        <hr/>
                        <Link to="/accommodation">VIEW ALL</Link>
                    </div>
                </div>
            </section>

            <section className="container-fluid moments">
                <div className="row">
                    <div className="col">
                        <div className="row">

                            <div className="col-xs col-sm-4 text-center">
                                <img className="img-fluid" src="assets/images/left.jpg" alt=""/>
                            </div>
                            <div className="col-xs col-sm-4 text-center text">
                                <h2>Turning moments into memories for our guests</h2>
                                <div className="col">
                                    We’re not just a place where you can accommodate. Ella Flower Garden Resort is the
                                    best you can find in Ella, Sri Lanka as a one stop for many traveler needs.
                                    <br/>
                                    When we come to accommodation you can have various types of rooms at Flower
                                    Garden such as Standard, Deluxe and Chalets. Every room has a spacious private
                                    balcony faced to Ella Rock.
                                </div>
                            </div>
                            <div className="col-xs col-sm-4 text-center">
                                <img className="img-fluid" src="assets/images/right.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid gallery">
                <div className="row">
                    <div className="col">
                        <div className="col gallery-heading-1">
                            ELLA
                        </div>
                        <div className="col gallery-heading-2">
                            RESORT
                        </div>
                        <div className="col gallery-heading-3">
                            GALLERY
                        </div>
                        <div className="col gallery-button">
                            <Link to="/gallery">VIEW GALLERY</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container experiences" id="experiences">
                <div className="row">
                    <div className="col text-center">
                        <h1>UNTOLD BEAUTY AND EXPERIENCES</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col-sm col-12">
                                <img src="assets/images/cropped.jpg" alt="" className="img-fluid"/>
                                <h2>Flower Garden Resort</h2>
                                <div className="col">
                                    A place where you can relax, dine and stay. If you're visiting
                                    Ella, Flower Garden Resort is where you must be.
                                </div>
                                <Link to="/accommodation">Read More</Link>
                            </div>
                            <div className="col-sm col-12">
                                <img src="assets/images/feature-2.jpg" alt="" className="img-fluid"/>
                                <h2>Ella Resort Chalet
                                </h2>
                                <div className="col">
                                    Chalets are separated from the main building and it's surrounded by
                                    trees with the view of Ella Gap. Rooms are large and have more privacy.
                                </div>
                                <Link to="/accommodation">Read More</Link>
                            </div>
                            <div className="col-sm col-12">
                                <img src="assets/images/feature-3.jpg" alt="" className="img-fluid"/>
                                <h2>Our Restaurant</h2>
                                <div className="col">
                                    View of Ella Gap and Tea estates offer you the most delicious food
                                    which is unparalleled
                                </div>
                                <Link to="/restaurant">Read More</Link>
                            </div>
                            <div className="col-sm col-12">
                                <img src="assets/images/feature-4.jpg" alt="" className="img-fluid"/>
                                <h2>Tours and Transport
                                </h2>
                                <div className="col">
                                    Our vehicle fleet consists of luxury vans, cars and also tuk tuk to provide you a
                                    safe and
                                    comfortable ride.
                                </div>
                                <Link to="/location">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid rooms">
                <div className="row">
                    <div className="col">
                        {/*<h2 className="text-center col">ACCOMMODATION</h2>*/}
                        <div className="row restaurant">
                            {this.getRooms()}
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    }

    getRooms = () => {
        // let rooms = [];
        // this.state.rooms.forEach((room, index) => {
        //     if (room.homePage) {
        //         rooms.push(<SingleRoom key={index} image={room.logo} name={room.title} description={room.description}/>)
        //     }
        // });
        // return rooms;


        let rooms = [];

        let count = 0;

        this.state.rooms.forEach((room, index) => {
            if (room.homePage) {
                if (count % 2 === 0) {
                    rooms.push(<div className="row" key={index}>
                        <div className="col-12 col-sm-6">
                            <img src={room.homeLogo} className={'img-fluid'} alt=""/>
                        </div>
                        <div className="col-12 col-sm-6 align-self-center text-center restaurant-description">
                            {/*<h3 className="col">{room.title}</h3>*/}
                            <h2 className="col">{room.subtitle}</h2>
                            <div className="col description">{room.description}</div>
                        </div>
                    </div>);
                } else {
                    rooms.push(<div className="row" key={index}>
                        <div className="col-12 col-sm-6 order-sm-6">
                            <img src={room.homeLogo} className={'img-fluid'} alt=""/>
                        </div>
                        <div className="col-12 col-sm-6 align-self-center text-center restaurant-description">
                            {/*<h3 className="col">{room.title}</h3>*/}
                            <h2 className="col">{room.subtitle}</h2>
                            <div className="col description">{room.description}</div>
                        </div>
                    </div>);
                }
                count++;
            }
        });

        return rooms;

    };
}

export default Home;
