import React, {Component} from "react";
import Header from '../page-components/Header'
import Footer from '../page-components/Footer'
import Slider from "../page-components/Slider";
import TextInput from "../page-components/TextInput";
import Errors from "../page-components/Errors";
import HelmetExport from "react-helmet/es/Helmet";
import Countries from "../data/Countries";

class ContactUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: [], countries: Countries.getCountries()
        };
        this.addError = this.addError.bind(this);
    }

    render() {
        return <div>

            <HelmetExport>
                <title>Ella Flower Garden Resort | Contact Us</title>
            </HelmetExport>
            <Header/>
            <Slider
                images={['assets/images/contact-slider.jpg', 'assets/images/contact-slider.jpg', 'assets/images/contact-slider.jpg',]}/>
            <section className="container-fluid restaurant">
                <div className="row">
                    <div className="col col-sm-6">'
                        <div className="col contact-wrapper">
                            <div className="contact-container col offset-sm-2 col-sm-8">
                                <h2>CONTACT</h2>
                                <div className="col">
                                    3rd Mile Post, Passara Rd,
                                    <br/>
                                    Ella, Sri Lanka
                                    <br/>
                                    <br/>
                                    +94 57 2050 480
                                    <br/>
                                    +94 57 4933 163
                                    <br/>
                                    <br/>
                                    ellaflowergarden@gmail.com
                                    <br/>
                                    info@ella-resort.com
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-sm-6 align-self-center text-center restaurant-description">
                        {/*<h3 className="col">LOREM IPSUM</h3>*/}
                        <h2 className="col">GENERAL INQUIRIES AND TOUR BOOKING</h2>
                        <div className="col form">
                            <Errors errors={this.state.errors}/>
                            <form>
                                <div className="form-group row">
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Name <span
                                        className="required">*</span></label>
                                    <div className="col-sm-10">
                                        <TextInput placeholder="Name" validation={this.addError}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword"
                                           className="col-sm-2 col-form-label">Email <span className="required">*</span></label>
                                    <div className="col-sm-10">
                                        <TextInput placeholder="Email" validation={this.addError}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword"
                                           className="col-sm-2 col-form-label">Telephone No. <span
                                        className="required">*</span></label>
                                    <div className="col-sm-10">
                                        <TextInput placeholder="Telephone No." validation={this.addError}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword"
                                           className="col-sm-2 col-form-label">Address</label>
                                    <div className="col-sm-10">
                                        <TextInput placeholder="Address" validation={this.addError}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword"
                                           className="col-sm-2 col-form-label">Country <span
                                        className="required">*</span></label>
                                    <div className="col-sm-10">
                                        <select name="country" id="country" className="form-control">
                                            <option value="">Select Country</option>
                                            {this.getCountries()}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="inputPassword"
                                           className="col-sm-2 col-form-label">Message <span
                                        className="required">*</span></label>
                                    <div className="col-sm-10">
                                        <textarea name="message" id="message" cols="30" rows="10"
                                                  className="form-control"></textarea>
                                    </div>
                                </div>
                                <div className="form-group row text-center">
                                    <button type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    }

    addError(error) {
        if (error) {
            this.setState({errors: [error]});
        } else {
            this.setState({errors: []});
        }
    }

    getCountries() {
        let countries = [];
        this.state.countries.forEach(function (country, index) {
            countries.push(<option key={index} value={country}>{country}</option>)
        });
        return countries;
    }
}

export default ContactUs;
