import React, {Component} from "react";
import Header from '../page-components/Header'
import Footer from '../page-components/Footer'
import Slider from "../page-components/Slider";
import HelmetExport from "react-helmet/es/Helmet";
import {CSSTransition} from "react-transition-group";

class ThingsToDo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            thing: {},
            lightBoxStatus: false,
            things_to_do: [

                {
                    title: "little adams peak",
                    description: "From the main strip in Ella, you can walk about 1.5kms to the start of the trail. The trail head is right next to Ella Flower Garden Resort.  you can also see where the trail hits the main road (Ella Flower Garden Resort).normally trip will take 2/3 hours in total (including picture taking). t is 1141 m in height and from the entrance it takes up to 30-45 min for the hike. Views from the summit are incredible. 360 degrees panoramic views with clouds rolling in, is a treat to watch for any traveler.Hike itself is a worthy experience as you walk through lush green tea estates, talking to locals who are plucking and collecting tea leaves, working in the estate.We recommend you go early mornings or late evenings for the best views and it will be less tiring.",
                    image: 'assets/images/thingstodo/little adams peak.jpg',
                },
                {
                    title: "Nine Arch Bridge Ella",
                    description: "Nine Arched Bridge 1 km from the hotel also called the Bridge in the Sky,It is one of the best examples of colonial-era railway construction in the country.The construction of the bridge is generally attributed to a local Ceylonese builder, P. K. Appuhami, in consultation with British engineers. The chief designer and project manager of the 'upcountry railway line of Ceylon' project was D. J. Wimalasurendra, a distinguished Ceylonese engineer and inventor.Sunrise is the best time to visit the Bridge. The light is stunning and you avoid the crowds.",
                    image: 'assets/images/thingstodo/Nine Arch Bridge Ella.jpg',
                },
                {
                    title: "Ravana Falls",
                    description: "Ravana Falls is another must visit in Ella. Hill country of Sri Lanka is quite popular for beautiful waterfalls and Ravana Falls is right at the top.The falls are quite impressive, and you can climb over some of the rocks to get a closer look.If you love water and wanna have some splash time, enjoying some hours in the water fall, then this is the place in Ella. You can spend sometime swimming, but be safe as the rocks are slippery It currently ranks as one of the widest falls in the country. This waterfall measures approximately 25 m (82 ft) in high and cascade from an oval-shaped concave rock outcrop. During the local wet season, the waterfall turns into what is said to resemble an areca flower with withering petals. But this is not the case in the dry season, where the flow of water reduces dramatically.",
                    image: 'assets/images/thingstodo/Ravana Falls.jpg',
                },
                {
                    title: "Ravana Cave",
                    description: "The Ravana Cave is located about 2 km from the Ella town and 11 km (7 mi) away from Bandarawela. It is quite a small cave, measuring about 50ft wide, 150ft long and 60ft high. History says that the cave is used by King Rawana to hide the Princess Sita. The cave lies 1,370 m (4,490 ft) above sea level on the foundation of a cliff, and is one of the most popular tourist attractions in Sri Lanka with a historical value.",
                    image: 'assets/images/thingstodo/Ravana Cave.jpg',
                },
                {
                    title: "Ella Rock",
                    description: "If you love living green, then Ella is obviously a place to visit. Ella Rock is one of the place which proves it.Trekkers would love the place. It’s bit tiring to do the hiking. But it is definitely worth it. Once you got to the summit you will realize why we said so. The panoramic views it provides with mist and cloud covering the area, especially in the early mornings and the late evenings are something you should witness and that would suffice enough to say that you visited Ella.Total time for climbing up and down is 4 hours. Yes, sounds like a long journey. But the way up there is with full of diversity. If you are starting form Ella train station, you would enjoy the rail walk, and soon as you started climbing up, you will be passing a waterfall, the rubber tree forest and tea plantations.On a rainy day, it can be slippery, so better be cautious with what you wear for the journey. Wear something suitable for a hiking and towards the end of the hike, route will get bit harder with the steep, still manageable.",
                    image: 'assets/images/thingstodo/Ella Rock .jpg',
                },
                {
                    title: "Lipton’s Seat",
                    description: "If you are staying in Ella for more than a day or two and visiting Haputale, make some time to visit the Lipton Seat.This is located about 18 km from Haputale (7km from Dambetenna Tea Factory)The name Lipton’s Seat says it all. It is a famous viewpoint 1970m above sea level. This precise spot in the Haputale Mountains overlooking the Dambetenna Tea Estate is where Sir Thomas Lipton used to sit and contemplate his plantation with its stony landscape and green tea bushes.",
                    image: 'assets/images/thingstodo/Lipton Seat.jpg',
                },
                {
                    title: "Adisham bungalow",
                    description: "If you are staying in Ella for more than a day or two and visiting Haputale, do not miss this monastery which is a highly recommended tourist attraction in the area.A 4 km drive from Haputale will lead you to Adisham, a monastery run by Benedictine Monks. Wrapped in the tranquility of the misty hills, Adisham Bungalow attracts every eye that falls on it. The pages of history reveal that the creator of this enthralling place is Sir Thomas Lister Villiers in 1931.",
                    image: 'assets/images/thingstodo/Adisham bungalow.jpg',
                },
                {
                    title: "Dowa Rock Temple",
                    description: "Another historical places for your list near Ella, a temple is thought to be done by King Walagamba in the in first century BC. If you are planning to stay some days in Ella, visit the temple to spend some peaceful time.Dowa Rock Temple lies few kilometers away from the Ella town on the Ella - Bandarawela Road. This is one of the many temples built by the king while taking refuge in the Uva province after an Indian invasion.The temple has gain popularity mainly due to its massive 38 feet Buddha Statue carved in the granite rock. But this temple also hides some secrets that time has forgotten.The uncompleted image of the Buddha is carved out of a granite boulder which is well hidden form the main road. No much of attention has been given to this statue and it seems to be slowly breaking up. At the top pf the boulder is a small stupa. This stupa is the on the same level as the road and it is the only indication of a temple to a traveler on the road.f you like to explore historical places in Sri Lanka and admire the Ravana History, then this is definitely a place to visit.",
                    image: 'assets/images/thingstodo/Dowa Rock Temple.jpg',
                },
                {
                    title: "Ella Wala Falls - Jungle Hidden waterfall",
                    description: "Ella Wala Falls is a small but beautiful waterfall lying hidden inside the jungle on the Ella – Wellawaya Road. Not the closest waterfall to Ella, but still people go there to enjoy a bath as it got a natural pool like area to have some fun. Nothing like an enchanting waterfall to provide you with a magnificent playground that you can truly enjoy, right?If you are planning on staying 2-3 days in Ella and got a vehicle, plan a visit to this place, you will enjoy some quality and peaceful time as it's not crowded at all like We highly recommend the place, if you love water and wanna have some splash time. But be safe on a rainy day as not easy to get some quick help!",
                    image: 'assets/images/thingstodo/Ella Wala Falls - Jungle Hidden waterfall.jpg',
                },
                {
                    title: "Train Travels in Ella",
                    description: "Train is a major transportation way for tourists in Sri Lanka. We recommend you to use a train when you come to Ella and enjoy the breathtaking views while standing on the open doors.Nuwara Eliya to Ella train experience is famous as one of world's beautiful train journey. Once you experienced this, you will certainly agree with us. When the train is passing the tea plantations, hills, bridges, tunnels and water falls, your camera will have a very busy time.",
                    image: 'assets/images/thingstodo/Train Travels in Ella.jpg',
                },
            ]
        };
    }

    componentDidMount() {
        var height_elements = document.querySelectorAll('.experiences .row .col .row .col-12 .col');

        var element_max_height = [];

        height_elements.forEach(function (height_element) {
            if (height_element.clientHeight > element_max_height) {
                element_max_height = height_element.clientHeight;
            }
        });

        height_elements.forEach(function (height_element) {
            var element_height = element_max_height + 20;
            height_element.style.height = element_height + 'px';
        });

        var h2_height_elements = document.querySelectorAll('.experiences .row .col .row .col-12 h2');

        var h2_element_max_height = 0;

        h2_height_elements.forEach(function (h2_height_element) {
            if (h2_height_element.clientHeight > h2_element_max_height) {
                h2_element_max_height = h2_height_element.clientHeight;
            }
        });

        h2_height_elements.forEach(function (h2_height_element) {
            h2_height_element.style.height = h2_element_max_height + 'px';
        });
    }

    render() {
        return <div>
            <HelmetExport>
                <title>Ella Flower Garden Resort | Things to Do</title>
            </HelmetExport>
            <Header/>
            <Slider
                images={['assets/images/t-t-d-s.jpg', 'assets/images/t-t-d-s.jpg', 'assets/images/t-t-d-s.jpg',]}/>

            <section className="container about-us">
                <div className="row">
                    <div className="col text-center">
                        <h1>ELLA SRI LANKA</h1>
                        <h2>THINGS TO DO</h2>
                        {/*<div className="col description">*/}
                        {/*    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has*/}
                        {/*    been the industry's standard dummy text ever since the 1500s, when an unknown printer took a*/}
                        {/*    galley of type and scrambled it to make a type specimen book. It has survived not only five*/}
                        {/*    centuries, but also the leap into electronic typesetting, remaining essentially unchanged.*/}
                        {/*    It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum*/}
                        {/*    passages, and more recently with desktop publishing software like Aldus PageMaker including*/}
                        {/*    versions of Lorem Ipsum.*/}
                        {/*</div>*/}
                        {/*<hr/>*/}
                        {/*<a href="#">VIEW ALL</a>*/}
                    </div>
                </div>
            </section>
            <section className="container experiences">
                <div className="row">
                    <div className="col">
                        <div className="row">
                            {this.getThingsToDo()}
                        </div>
                    </div>
                </div>
            </section>
            <CSSTransition
                in={this.state.lightBoxStatus}
                timeout={200} className="gallery-light-box">
                <div className="gallery-light-box" key="gallery-light-box">
                    <div className="gallery-light-box-close float-right">
                        <button type="button" onClick={this.closeLightBox.bind(this)}>X</button>
                    </div>
                    <div className="readmore">
                        <div className="col-sm-4 col-12">
                            <img src={this.state.thing.image} alt="" className="float-left"/>
                        </div>
                        <div className="col-12 col-sm-8">
                            <h2>{this.state.thing.title}</h2>
                            <span>{this.state.thing.description}</span>
                        </div>
                    </div>
                </div>
            </CSSTransition>
            <Footer/>
        </div>
    }

    getThingsToDo() {
        let things_to_do = [];

        this.state.things_to_do.forEach((thing, index) => {
            things_to_do.push(<div key={index} className="col-12 col-sm-3">
                <img src={thing.image} alt="" className="img-fluid"/>
                <h2 className="todo">{thing.title}</h2>
                <div className="col">
                    {this.getShortDescription(thing.description)}
                </div>
                <button type="button" onClick={this.openLightBox.bind(this, thing)}>Read More</button>
            </div>)
        });

        return things_to_do;
    }

    getShortDescription(description) {
        return description.substring(0, 150) + ' ...';
    }

    openLightBox(thing) {
        this.setState({thing: thing, lightBoxStatus: true,});
    }

    closeLightBox() {
        this.setState({lightBoxStatus: false,});
    }
}

export default ThingsToDo;
